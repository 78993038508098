<script setup>
import BuscaAdmin from "@/components/BuscaAdmin.vue";
import CardEstabelecimento from "@/components/CardEstabelecimento.vue";
import DataTable from "@/components/DataTable.vue";
import FiltroBusca from "@/components/FiltroBusca.vue";
import InfoEstabelecimentoModal from "@/components/ModalInfoEstabelecimento.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import MenuCategorias from "@/components/MenuCategorias.vue";
import ModalIndicarParceiro from "@/components/ModalIndicarParceiro.vue";
import SelectCategorias from "@/components/SelectCategorias.vue";
import ObserverVue from "@/components/ObserverVue.vue";
import AbrangenciaAPI from "@/services/AbrangenciaAPI";
import ParceiroAPI from "@/services/ParceiroAPI";
import store from "@/store";
import { abrirModal } from "@/utils/modalConfig";
import Multiselect from "@vueform/multiselect";
import {
computed,
onBeforeMount,
onMounted,
ref,
watch,
watchEffect
} from "vue";

const abrangencia = ref(null);

const abrangencias = computed(() =>
  store.getters["abrangenciaModule/getData"].map(
    (abrangencia) => abrangencia.local
  )
);

const modalIndicarParceiro = ref("");

const isAdmin = computed(() => store.getters["authModule/getIsAdmin"]);

const parceiroSelecionado = computed(
  () => store.getters["parceiroModule/getParceiroSelecionado"]
);

const categoriaSelecionada = computed(
  () => store.getters["categoriaModule/getCategoriaSelecionada"]
);

const abrangenciaStore = computed(
  () => store.getters["abrangenciaModule/getAbrangenciaSelecionada"]
);

const cards = computed(() =>
  categoriaSelecionada.value && !isAdmin.value && abrangencia.value
    ? store.getters["parceiroModule/getFiltrarPorAbrangencia"]
        .filter((parceiro) => parceiro.beneficio.status)
        .filter(
          (parceiro) => parceiro.categoria.nome == categoriaSelecionada.value
        )
    : categoriaSelecionada.value && !isAdmin.value && !abrangencia.value
    ? store.getters["parceiroModule/getTodosOsParceiros"]
        .filter((parceiro) => parceiro.beneficio.status)
        .filter(
          (parceiro) => parceiro.categoria.nome == categoriaSelecionada.value
        )
    : !categoriaSelecionada.value && !isAdmin.value && abrangencia.value
    ? store.getters["parceiroModule/getFiltrarPorAbrangencia"].filter(
        (parceiro) => parceiro.beneficio.status
      )
    : !categoriaSelecionada.value && !isAdmin.value && !abrangencia.value
    ? store.getters["parceiroModule/getParceirosPaginados"].filter(
        (parceiro) => parceiro.beneficio.status
      )
    : categoriaSelecionada.value && isAdmin.value && abrangencia.value
    ? store.getters["parceiroModule/getFiltrarPorAbrangencia"].filter(
        (parceiro) => parceiro.categoria.nome == categoriaSelecionada.value
      )
    : categoriaSelecionada.value && isAdmin.value && !abrangencia.value
    ? store.getters["parceiroModule/getTodosOsParceiros"].filter(
        (parceiro) => parceiro.categoria.nome == categoriaSelecionada.value
      )
    : !categoriaSelecionada.value && isAdmin.value && abrangencia.value
    ? store.getters["parceiroModule/getFiltrarPorAbrangencia"]
    : !categoriaSelecionada.value && isAdmin.value && !abrangencia.value
    ? store.getters["parceiroModule/getParceirosPaginados"]
    : null
);

const itensCard = computed(() =>
  cards.value
    ? cards.value.map(function (card) {
        return {
          id: card.id,
          nome: card.nome,
          imagem: card.imagem,
          categoria: card.categoria.nome,
          telefone: card.contato.telefone,
          whatsapp: card.contato.whatsapp,
          email: card.contato.email,
          rede: card.contato.rede,
          cep: card.endereco.cep,
          logradouro: card.endereco.logradouro,
          numero: card.endereco.numero,
          bairro: card.endereco.bairro,
          cidade: card.endereco.cidade,
          uf: card.endereco.uf,
          complemento: card.endereco.complemento,
          comprovacao: card.beneficio.comprovacao,
          inicioContrato: card.beneficio.inicioContrato,
          fimContrato: card.beneficio.fimContrato,
          status: card.beneficio.status ? "Ativo" : "Inativo",
          observacao: card.beneficio.observacao,
          anuncio: card.beneficio.anuncio,
          abrangencia: card.beneficio.abrangencia
            .map((abrangencia) => abrangencia.local)
            .toString()
            .replace(/,/g, ", "),
        };
      })
    : ""
);

const textoBusca = ref(false);

const dados = ref(itensCard.value);

const loading = computed(() => store.getters["parceiroModule/getLoading"]);

const loadingMore = computed(() => store.getters["parceiroModule/getLoadingMore"]);

const filtrar = (valor) => {
  dados.value = valor.map(function (item) {
        return {
          id: item.id,
          nome: item.nome,
          imagem: item.imagem,
          categoria: item.categoria.nome,
          telefone: item.contato.telefone,
          whatsapp: item.contato.whatsapp,
          email: item.contato.email,
          rede: item.contato.rede,
          cep: item.endereco.cep,
          logradouro: item.endereco.logradouro,
          numero: item.endereco.numero,
          bairro: item.endereco.bairro,
          cidade: item.endereco.cidade,
          uf: item.endereco.uf,
          complemento: item.endereco.complemento,
          comprovacao: item.beneficio.comprovacao,
          inicioContrato: item.beneficio.inicioContrato,
          fimContrato: item.beneficio.fimContrato,
          status: item.beneficio.status ? "Ativo" : "Inativo",
          observacao: item.beneficio.observacao,
          anuncio: item.beneficio.anuncio,
          abrangencia: item.beneficio.abrangencia
            .map((abrangencia) => abrangencia.local)
            .toString()
            .replace(/,/g, ", "),
        };
      });

  textoBusca.value = true;
};

watchEffect(async () => {
  if (abrangencia.value && categoriaSelecionada.value) {
    await ParceiroAPI.findByAbrangencia(abrangencia.value);
    dados.value = itensCard.value;
  }
  if (abrangencia.value && !categoriaSelecionada.value) {
    await ParceiroAPI.findByAbrangencia(abrangencia.value);
    dados.value = itensCard.value;
  } else {
    dados.value = itensCard.value;
  }
});

watch(itensCard, () => {
  dados.value = itensCard.value;
});

watch(isAdmin, () => {
  abrangencia.value = null
});

let parceiros;

onBeforeMount(() => {
  ParceiroAPI.getParceirosPaginados(store.getters["parceiroModule/getPaginaAtual"]);
  ParceiroAPI.getTodosParceiros();
  AbrangenciaAPI.getAbrangencias();
  parceiros = computed(() => (store.getters["parceiroModule/getTodosOsParceiros"].filter((parceiro) => isAdmin.value || parceiro.beneficio.status)));
});

watch(itensCard, () => {
  dados.value = itensCard.value;
});

onMounted(() => {
  modalIndicarParceiro.value = document.querySelector("#modal-indicarParceiro");
});

function abrangenciaSelecionada() {
  if (!abrangencia.value) {
    store.commit("parceiroModule/setLoading", false);
  }

  if (abrangencia.value) {
    store.commit("parceiroModule/setLoading", true);

    if (abrangencia.value == abrangenciaStore.value) {
      store.commit("parceiroModule/setLoading", false);
    }
  }

  store.commit(
    "abrangenciaModule/setAbrangenciaSelecionada",
    abrangencia.value
  );
}

let totalPaginas = computed(() => (store.getters["parceiroModule/getTotalPaginas"]));

let page;

const loadMore = async () => {
  page = computed(() => (store.getters["parceiroModule/getPaginaAtual"]));

  if (page.value < totalPaginas.value && textoBusca.value == false) {
    store.commit("parceiroModule/setLoadingMore", true);
    store.commit("parceiroModule/setPaginaAtual", page.value = page.value + 1);

    await ParceiroAPI.getParceirosPaginados(store.getters["parceiroModule/getPaginaAtual"]);

    store.commit("parceiroModule/setLoadingMore", false);
  }
}
</script>

<template>
  <MenuCategorias class="menu-categorias" />
  <SelectCategorias class="select-categorias" />
  <div class="busca-container">
    <BuscaAdmin v-if="isAdmin" />
    <Multiselect
      class="select-abrangencia"
      placeholder="Filtrar por abrangência"
      v-model="abrangencia"
      :options="abrangencias"
      @click="abrangenciaSelecionada()"
    />
    <FiltroBusca
      class="busca"
      :data="parceiros"
      @search="(data) => filtrar(data)"
      @busca-mudou="(valor) => (valor ? textoBusca = false : textoBusca = true)"
    />
  </div>
  <div class="loading" v-if="loading">
    <LoadingComponent />
  </div>
  <div class="box" v-if="!loading">
    <DataTable v-if="isAdmin" :data="dados" :textoBusca="textoBusca"/>
    <div v-if="!isAdmin">
      <div class="titulo-box">
        <div class="titulo-e-divisoria">
          <div class="divisoria"></div>
          <h2 class="titulo">
            {{
              !categoriaSelecionada && dados.length == 0 && !textoBusca
                ? "Nenhuma parceria disponível"
                : !abrangencia && !categoriaSelecionada && !textoBusca
                ? `${parceiros.length} parcerias disponíveis`
                : !categoriaSelecionada && dados.length == 0 && textoBusca
                ? `Resultado da busca: nenhuma parceria encontrada`
                : !categoriaSelecionada && dados.length == 1 && textoBusca
                ? `Resultado da busca: ${dados.length} parceria encontrada`
                : !categoriaSelecionada && dados.length > 1 && textoBusca
                ? `Resultado da busca: ${dados.length} parcerias encontradas`
                : categoriaSelecionada && dados.length == 0 && !textoBusca
                ? `Não há parceria disponível em ${categoriaSelecionada}`
                : categoriaSelecionada && dados.length == 1 && !textoBusca
                ? `${dados.length} parceria disponível em ${categoriaSelecionada}`
                : categoriaSelecionada && dados.length == 0 && textoBusca
                ? `Resultado da busca: nenhuma parceria encontrada`
                : categoriaSelecionada && dados.length == 1 && textoBusca
                ? `Resultado da busca: ${dados.length} parceria encontrada`
                : categoriaSelecionada && dados.length > 1 && textoBusca
                ? `Resultado da busca: ${dados.length} parcerias encontradas`
                : categoriaSelecionada && dados.length > 1 && !textoBusca
                ? `${dados.length} parcerias disponíveis em ${categoriaSelecionada}`
                : abrangencia && !categoriaSelecionada && !textoBusca
                ? `${dados.length} parcerias disponíveis para ${abrangencia}`
                : null
            }}
          </h2>
        </div>
        <h3
          class="indicar-parceiro-h3"
          @click="abrirModal(modalIndicarParceiro)"
        >
          <font-awesome-icon
            :icon="['fas', 'circle-info']"
            style="color: var(--cor-principal)"
          />
          Indicar parceiro
        </h3>
      </div>
      <div class="flex-container">
        <div class="grid-item" v-for="(card, index) in dados" :key="index">
          <CardEstabelecimento
            :parceiroSelecionado="card"
            :imagem="card.imagem"
            :estabelecimento="card.nome"
            :categoria="card.categoria"
            :anuncio="card.anuncio"
            :abrangencia="card.abrangencia"
          />
        </div>
        <ObserverVue @intersect="loadMore"/>
        <div class="loadingMore" v-if="loadingMore">
          <LoadingComponent />
        </div>
        <div v-if="parceiroSelecionado">
          <InfoEstabelecimentoModal
            :imagem="parceiroSelecionado.imagem"
            :estabelecimento="parceiroSelecionado.nome"
            :categoria="parceiroSelecionado.categoria"
            :telefone="parceiroSelecionado.telefone"
            :whatsapp="parceiroSelecionado.whatsapp"
            :email="parceiroSelecionado.email"
            :rede="parceiroSelecionado.rede"
            :cep="parceiroSelecionado.cep"
            :logradouro="parceiroSelecionado.logradouro"
            :numero="parceiroSelecionado.numero"
            :bairro="parceiroSelecionado.bairro"
            :cidade="parceiroSelecionado.cidade"
            :uf="parceiroSelecionado.uf"
            :complemento="parceiroSelecionado.complemento"
            :comprovacao="parceiroSelecionado.comprovacao"
            :fimContrato="parceiroSelecionado.fimContrato"
            :observacao="parceiroSelecionado.observacao"
            :anuncio="parceiroSelecionado.anuncio"
            :abrangencia="parceiroSelecionado.abrangencia"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalIndicarParceiro />
</template>

<style lang="scss" scoped>
.select-abrangencia {
  width: 48%;
  margin-left: 1rem;
  height: 10px;
  margin-top: 1rem;
}

.busca-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.busca {
  flex: 1;
  width: 52%;
  height: 10px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 800px) {
  .select-abrangencia {
    width: 100%;
    margin-right: 1rem;
  }

  .busca-container {
    display: flex;
    flex-wrap: wrap;
  
  .busca {
    width: 100%;
  }
}
}

@media screen and (max-width: 600px) {
  .select-abrangencia {
    width: 100%;
    margin-right: 1rem;
  }

  .busca-container {
    display: flex;
    flex-wrap: wrap;
  
  .busca {
    width: 100%;
  }
}
}

@media screen and (max-width: 476px) {
  .select-abrangencia {
    width: 100%;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .busca-container {
    display: flex;
    flex-wrap: wrap;
  
  .busca {
    width: 100%;
  }
}
}

.loading {
  @extend .box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loadingMore {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu-categorias {
  @media screen and (max-width: 781px) {
    display: none;
  }
}

.select-categorias {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;

  @media screen and (min-width: 781px) {
    display: none;
  }
}

.titulo-e-divisoria {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.indicar-parceiro-h3 {
  font-weight: var(--font-bold);
  color: var(--cor-texto);
  font-size: 1.2rem;
  transition: 0.3s;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.65rem;
    }

    @media screen and (max-width: 434px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 418px) {
      font-size: 0.55rem;
    }

    @media screen and (max-width: 402px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 318px) {
      font-size: 0.4rem;
    }
}

.indicar-parceiro-h3:hover {
  cursor: pointer;
  color: var(--cor-principal);
}

.titulo-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 2.2rem 0.5rem;

  @media screen and (max-width: 476px) {
    margin: 0.2rem 0 1rem 0.5rem;
  }

  @media screen and (max-width: 318px) {
    margin: 0rem 0 0.2rem 0.5rem;
  }

  .divisoria {
    width: 4.5px;
    height: 20px;
    background-color: var(--cor-principal);
    margin-right: 0.5rem;

    @media screen and (max-width: 600px) {
      height: 30px;
    }

    @media screen and (max-width: 476px) {
      height: 20px;
    }
    @media screen and (max-width: 386px) {
      height: 13px;
    }
  }

  h2 {
    font-weight: var(--font-bold);
    color: var(--cor-texto);
    font-size: 1.2rem;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.65rem;
    }

    @media screen and (max-width: 434px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 418px) {
      font-size: 0.55rem;
    }

    @media screen and (max-width: 402px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 318px) {
      font-size: 0.4rem;
    }
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  align-self: center;
}

.grid-item {
  margin: 1rem 0.5rem;
  width: 300px;

  @media screen and (max-width: 476px) {
    margin: 0.3rem 0.5rem;
  }

  @media screen and (max-width: 402px) {
    margin: 0.2rem 0.5rem;
  }

  @media screen and (max-width: 318px) {
    margin: 0rem 0.5rem;
  }
}
</style>
