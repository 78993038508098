<script setup>
import { fecharModal } from "@/utils/modalConfig";
import { onMounted, ref } from "vue";
import BotaoComponent from "./BotaoComponent.vue";

const modal = ref("");

function fechar() {
    fecharModal(modal.value);
}

onMounted(() => {
    modal.value = document.querySelector("#modal-indicarParceiro");
});
</script>

<template>
    <div class="modal" id="modal-indicarParceiro">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <div>
                    <img class="logo-alterdata" src="@/assets/logo_alterdata_small.svg" alt="Logo da Alterdata" />
                </div>
                <div>
                    <span class="titulo">Sua sugestão é importante para nós!</span>
                </div>
            </header>
            <section class="modal-card-body">
                Deseja nos recomendar um estabelecimento para fecharmos parceria? <br/>
                Entre em contato conosco através do <a href="https://appbimer.alterdata.com.br/crm" target="_blank">CRM</a>, 
                abrindo um atendimento e preenchendo os campos da seguinte forma: <br/><br/>
                
                <div class="passo-a-passo-box">
                    Pessoa: seu código de colaborador, <br/>
                    Tipo de atendimento: 709 - Solicitação RH,  <br/>
                    Texto padrão do parecer: 527 - Sugestão de parceria,  <br/>
                    Usuário de próximo contato: PARCERIAS <br/>
                </div>

                </section>
            <footer class="modal-card-foot">
                <BotaoComponent @click="fechar()" textoBotao="FECHAR"/>
            </footer>
        </div>
    </div>
</template>


<style scoped>
.modal-card-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--cor-secundaria);
    height: 230px;
    padding-top: 10px;

}

.logo-alterdata{
    height: 140px;
    width: 140px;
    padding: 15px;
    margin-top: 15px;
}

.titulo{
    font-weight: var(--font-bold);
    font-size: 1.3rem;
}

.modal-card-body {
    background-color: var(--cor-secundaria);
    padding: 0 1.1rem 1.1rem 1.1rem;
    text-align: center;
}

.passo-a-passo-box{
    background-color: #f7f7f7;;
    border-radius: 7px;
    padding: 10px;
}

.modal-card-foot {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--cor-secundaria);
    align-items: center;
    height: 80px;
}
</style>