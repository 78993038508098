<script setup>
import ParceiroAPI from "@/services/ParceiroAPI";
import store from "@/store";
import { fecharModal } from "@/utils/modalConfig";
import toast from "@/utils/toastConfig";
import { getAbrangenciasByLocais, getCategoriaByNome } from "@/utils/utils";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import { computed, onMounted, ref, watchEffect } from "vue";
import BotaoComponent from "./BotaoComponent.vue";
import TextArea from "./TextArea.vue";
import TextInput from "./TextInput.vue";

const imagemInserida = ref({});
const uploadImage = ref(null);
const nomeDaImagemInserida = ref("");

const modal = ref("");
const modalConfirm = ref("");
const inputErrors = ref([]);
const helperText = "Este campo é obrigatório!";
const acao = computed(() => store.state.acaoModal);
const title = computed(() => `${store.state.acaoModal} Estabelecimento`);
const parceiro = computed(
  () => store.getters["parceiroModule/getParceiroSelecionadoEdit"]
);
const categorias = computed(() =>
  store.getters["categoriaModule/getData"].map((categoria) => categoria.nome)
);
const abrangencias = computed(() =>
  store.getters["abrangenciaModule/getData"].map(
    (abrangencia) => abrangencia.local
  )
);

const loading = computed(() => store.getters['parceiroModule/getLoading']);

function buscarEndereco() {
  const cep = parceiro.value.endereco.cep.replace(/\D/g, "");
  if (cep.length !== 8) {
    return;
  }

  axios
    .get(`https://viacep.com.br/ws/${cep}/json/`)
    .then((response) => {
      const endereco = response.data;
      if (!endereco.erro) {
        parceiro.value.endereco.logradouro = endereco.logradouro;
        parceiro.value.endereco.bairro = endereco.bairro;
        parceiro.value.endereco.cidade = endereco.localidade;
        parceiro.value.endereco.uf = endereco.uf;
      }
    })
    .catch((error) => {
      store.commit("setErros", error.response.data);
    });
}

function cadastrarParceiro() {
  const novoParceiro = {
    nome: parceiro.value.nome,
    categoria: getCategoriaByNome(parceiro.value.categoria),
    contato: {
      telefone: parceiro.value.contato.telefone,
      whatsapp: parceiro.value.contato.whatsapp,
      email: parceiro.value.contato.email,
      rede: parceiro.value.contato.rede,
    },
    endereco: {
      cep: parceiro.value.endereco.cep,
      logradouro: parceiro.value.endereco.logradouro,
      numero: parceiro.value.endereco.numero,
      bairro: parceiro.value.endereco.bairro,
      cidade: parceiro.value.endereco.cidade,
      uf: parceiro.value.endereco.uf,
      complemento: parceiro.value.endereco.complemento,
    },
    beneficio: {
      anuncio: parceiro.value.beneficio.anuncio,
      abrangencia: getAbrangenciasByLocais(
        parceiro.value.beneficio.abrangencia
      ),
      comprovacao: parceiro.value.beneficio.comprovacao,
      observacao: parceiro.value.beneficio.observacao,
      inicioContrato: parceiro.value.beneficio.inicioContrato,
      fimContrato: parceiro.value.beneficio.fimContrato,
      status: parceiro.value.beneficio.status,
    },
  };

  if (
    !novoParceiro.nome ||
    !nomeDaImagemInserida.value ||
    !novoParceiro.categoria ||
    !novoParceiro.beneficio.anuncio ||
    !novoParceiro.beneficio.anuncio ||
    !novoParceiro.beneficio.inicioContrato ||
    !novoParceiro.beneficio.comprovacao ||
    novoParceiro.beneficio.abrangencia.length === 0
  ) {
    store.commit("parceiroModule/setLoading", false);
    return toast.abrirToast("error", "Preencha os campos obrigatórios.");
  }

  ParceiroAPI.postParceiro(novoParceiro, imagemInserida.value).then( async () => {
    toast.abrirToast("success", "Estabelecimento cadastrado com sucesso!");
    reiniciarScrollBar();
    limparInputDeImagem();
    fecharModal(modal.value);
    store.commit("parceiroModule/setInputDisabled", true);
    await ParceiroAPI.getTodosParceiros();
    store.commit("parceiroModule/setInputDisabled", false);
  }).catch(error => {
    store.commit("parceiroModule/setLoading", false);
    toast.abrirToast("error", error);
  })
}

function inserirImagem() {
  imagemInserida.value = uploadImage.value.files[0];
  nomeDaImagemInserida.value = imagemInserida.value.name;

  const limiteDeCaracteres = 15;
  if (nomeDaImagemInserida.value.length > limiteDeCaracteres) {
    nomeDaImagemInserida.value =
      nomeDaImagemInserida.value.slice(0, limiteDeCaracteres) + "...";
  }
}

function limparInputDeImagem() {
  uploadImage.value.value = "";
  nomeDaImagemInserida.value = "";
  imagemInserida.value = {};
}

function editarParceiro() {
  if (
    !parceiro.value.nome ||
    !parceiro.value.categoria ||
    !parceiro.value.beneficio.anuncio ||
    !parceiro.value.beneficio.anuncio ||
    !parceiro.value.beneficio.inicioContrato ||
    !parceiro.value.beneficio.comprovacao ||
    parceiro.value.beneficio.abrangencia.length === 0
  ) {
    store.commit("parceiroModule/setLoading", false);
    return toast.abrirToast("error", "Preencha os campos obrigatórios.");
  }
  ParceiroAPI.editParceiro(parceiro.value).then( async () => {
    toast.abrirToast("success", "Estabelecimento editado com sucesso!");
    reiniciarScrollBar();
    fecharModal(modal.value);
    store.commit("parceiroModule/setInputDisabled", true);
    await ParceiroAPI.getTodosParceiros();
    store.commit("parceiroModule/setInputDisabled", false);
  }).catch(error => {
    store.commit("parceiroModule/setLoading", false);
    toast.abrirToast("error", error);
  })
}

function gerenciar() {
  if (acao.value == "Cadastrar") {
    store.commit("parceiroModule/setLoading", true);
    cadastrarParceiro();
  }
  if (acao.value == "Editar") {
    store.commit("parceiroModule/setLoading", true);
    editarParceiro();
  }
}

function cancelar() {
  inputErrors.value = [];
  reiniciarScrollBar();
  fecharModal(modal.value);
  if (acao.value == "Cadastrar") {
    limparInputDeImagem();
  }
}

function reiniciarScrollBar() {
  let bodyDoModal = document.getElementById("modal-body");
  bodyDoModal.scrollTop = 0;
}

function validar(index) {
  setTimeout(
    () =>
      (index == 1 && parceiro.value.categoria) ||
      (index == 2 && parceiro.value.beneficio.abrangencia.length > 0)
        ? (inputErrors.value[index] = false)
        : (inputErrors.value[index] = true),
    110
  );
}

onMounted(() => {
  modal.value = document.querySelector("#modal-gerenciarEstabelecimento");
  modalConfirm.value = document.querySelector("#modal-confirm");

  document
    .querySelectorAll(".multiselect-wrapper")
    .forEach((multiselect, index) => {
      multiselect.addEventListener("focusout", () => validar(index));
    });
});

watchEffect(() => {
  if (acao.value == "Editar" && parceiro.value.endereco.cep) {
    buscarEndereco();
  }

  if (parceiro.value.categoria) inputErrors.value[1] = false;
  if (parceiro.value.beneficio.abrangencia) inputErrors.value[2] = false;
});
</script>

<template>
  <div class="modal" id="modal-gerenciarEstabelecimento">
    <div class="modal-background">
      <div class="modal-card">
        <header class="modal-card-head">
          <span class="texto-titulo"> {{ title }} </span>
          <div class="botao-fechar">
            <button
              id="delete"
              class="delete is-large"
              :class="loading ? 'botao_desabilitado' : null"
              aria-label="close"
              @click="cancelar"
              :disabled="loading"
            ></button>
          </div>
        </header>
        <section class="modal-card-body" id="modal-body">
          <span class="modal-section-titulo"> Dados </span>
          <div class="columns">
            <div
              :class="{
                'column is-5': acao == 'Cadastrar',
                'column is-8': acao == 'Editar',
              }"
            >
              <TextInput
                required
                label="Nome"
                placeholder="Digite o nome do estabelecimento"
                v-model="parceiro.nome"
                :disabled="loading"
              />
            </div>
            <div class="column is-3" v-if="acao == 'Cadastrar'">
              <label class="label"
                >Imagem
                <font-awesome-icon
                  icon="fa-solid fa-star-of-life"
                  class="icon"
                />
              </label>
              <label class="botao-upload-image" for="upload-image" disabled="loading">
                <span v-if="!nomeDaImagemInserida">Selecionar imagem</span>
                <span v-else class="nome-da-imagem-selecionada">{{
                  nomeDaImagemInserida
                }}</span>
                <font-awesome-icon
                  class="small-icon"
                  icon="fa-solid fa-upload"
                />
              </label>
              <input
                type="file"
                accept="image/*"
                class="upload-image"
                id="upload-image"
                ref="uploadImage"
                @change="inserirImagem"
                :disabled="loading"
              />
            </div>
            <div class="column is-4">
              <div class="field">
                <label class="label"
                  >Categoria
                  <font-awesome-icon
                    icon="fa-solid fa-star-of-life"
                    class="icon"
                  />
                </label>
                <Multiselect
                  placeholder="Selecione..."
                  v-model="parceiro.categoria"
                  :options="categorias"
                  :class="{ danger: inputErrors[1] }"
                  :disabled="loading"
                />
                <p class="help is-danger" v-if="inputErrors[1]">
                  {{ helperText }}
                </p>
              </div>
            </div>
          </div>
          <div class="columns is-multiline is-mobile">
            <div class="column is-one-quarter">
              <TextInput
                label="Telefone"
                placeholder="(00) 0000-0000"
                v-mask="['(##) #####-####', '(##) ####-####']"
                v-model="parceiro.contato.telefone"
                :disabled="loading"
              />
            </div>
            <div class="column is-one-quarter">
              <TextInput
                label="WhatsApp"
                placeholder="(00) 0000-0000"
                v-mask="['(##) #####-####', '(##) ####-####']"
                v-model="parceiro.contato.whatsapp"
                :disabled="loading"
              />
            </div>
            <div class="column is-one-quarter">
              <TextInput
                label="Site ou Rede social"
                placeholder="Digite o link do site ou da rede social"
                v-model="parceiro.contato.rede"
                :disabled="loading"
              />
            </div>
            <div class="column is-one-quarter">
              <TextInput
                label="Email"
                placeholder="Digite o email"
                v-model="parceiro.contato.email"
                :disabled="loading"
              />
            </div>
          </div>
          <span class="modal-section-titulo"> Endereço </span>
          <div class="columns is-multiline is-mobile">
            <div class="column is-one-quarter">
              <TextInput
                label="CEP"
                @input="buscarEndereco()"
                placeholder="Digite o CEP"
                v-mask="'#####-###'"
                v-model="parceiro.endereco.cep"
                :disabled="loading"
              />
            </div>
            <div class="column is-half">
              <TextInput
                label="Logradouro"
                placeholder="Digite o nome da rua, avenida, etc"
                v-model="parceiro.endereco.logradouro"
                :disabled="loading"
              />
            </div>
            <div class="column is-one-quarter">
              <TextInput
                label="Número"
                placeholder="Digite o número"
                v-model="parceiro.endereco.numero"
                :disabled="loading"
              />
            </div>
          </div>
          <div class="columns is-multiline is-mobile">
            <div class="column is-3">
              <TextInput
                label="Cidade"
                placeholder="Digite a cidade"
                v-model="parceiro.endereco.cidade"
                :disabled="loading"
              />
            </div>
            <div class="column is-1">
              <TextInput
                label="UF"
                placeholder="UF"
                v-model="parceiro.endereco.uf"
                :disabled="loading"
              />
            </div>
            <div class="column is-4">
              <TextInput
                label="Bairro"
                placeholder="Digite o bairro"
                v-model="parceiro.endereco.bairro"
                :disabled="loading"
              />
            </div>
            <div class="column is-4">
              <TextInput
                label="Complemento"
                placeholder="Digite o complemento"
                v-model="parceiro.endereco.complemento"
                :disabled="loading"
              />
            </div>
          </div>
          <span class="modal-section-titulo"> Benefício </span>
          <div class="columns is-multiline is-mobile">
            <div class="column is-8">
              <TextArea
                required
                label="Anúncio"
                placeholder="Digite o anúncio do benefício"
                v-model="parceiro.beneficio.anuncio"
                :rows="1"
                :disabled="loading"
              ></TextArea>
            </div>
            <div class="column is-2">
              <TextInput
                required
                label="Início da Validade"
                type="date"
                v-model="parceiro.beneficio.inicioContrato"
                :disabled="loading"
              />
            </div>
            <div class="column is-2">
              <TextInput
                label="Fim da Validade"
                type="date"
                v-model="parceiro.beneficio.fimContrato"
                :disabled="loading"
              />
            </div>
          </div>
          <div class="columns is-multiline is-mobile">
            <div class="column is-half">
              <TextArea
                required
                label="Comprovação"
                placeholder="Digite as exigências para obter o benefício"
                v-model="parceiro.beneficio.comprovacao"
                :rows="1"
                :disabled="loading"
              >
              </TextArea>
            </div>
            <div class="column is-half">
              <div class="field">
                <label class="label">
                  Abrangência
                  <font-awesome-icon
                    icon="fa-solid fa-star-of-life"
                    class="icon"
                  />
                </label>
              </div>
              <Multiselect
                placeholder="Selecione..."
                v-model="parceiro.beneficio.abrangencia"
                :options="abrangencias"
                mode="tags"
                searchable
                noResultsText="Nenhuma abrangência foi encontrada"
                :class="{ danger: inputErrors[2] }"
                :disabled="loading"
              />
              <p class="help is-danger" v-if="inputErrors[2]">
                {{ helperText }}
              </p>
            </div>
          </div>
          <div class="columns is-multiline is-mobile">
            <div class="column is-8">
              <div class="control">
                <TextArea
                  label="Observações"
                  placeholder="Digite observações sobre o desconto oferecido"
                  v-model="parceiro.beneficio.observacao"
                  :rows="3"
                  :disabled="loading"
                ></TextArea>
              </div>
            </div>
            <div class="column is-4">
              <div class="botoes">
                <button
                  class="button is-info is-loading"
                  v-if="loading"
                ></button>
                <BotaoComponent
                  @click="gerenciar"
                  v-if="acao == 'Editar' && !loading"
                  textoBotao="SALVAR"
                />
                <BotaoComponent
                  @click="gerenciar"
                  v-if="acao == 'Cadastrar' && !loading"
                  textoBotao="CADASTRAR"
                />
                <BotaoComponent
                  id="botao-cancelar"
                  class="botao-cancelar"
                  @click="cancelar"
                  textoBotao="CANCELAR"
                  :disabled="loading"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-card {
  background-color: var(--cor-background);
  border-radius: 12px;
  border: none;
  width: 80%;
  height: 88%;
  margin-top: 2.5rem;

  @media screen and (min-width: 1400px) {
    width: 80%;
    height: 75%;
  }
}

.modal-card-head {
  border: none;
  background-color: var(--cor-background);
  justify-content: space-between;
  align-items: center;
  height: 1rem;
}

.modal-card-body {
  background-color: var(--cor-background);
  margin-top: 2rem;
  padding: 0 1rem 1rem 1rem;
}

.texto-titulo {
  color: var(--cor-principal);
  font-size: 24px;
  font-weight: var(--font-medium);
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}

.small-icon {
  font-size: 1.1rem;
  color: var(--cor-principal);
}

.modal-section-titulo {
  color: var(--cor-principal);
  font-weight: var(--font-medium);
  font-size: 14px;
}

.column {
  padding: 12px 12px 0px 12px;
}

.botao-fechar {
  margin-top: 2rem;
}

.botao_desabilitado {
  opacity: 70%;
  cursor: not-allowed;
}

.control {
  margin-bottom: 20px;
}

.botoes {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
}

button {
  width: 8rem;
}

.botao-editar {
  margin-left: 15px;
}

.danger {
  border-color: var(--cor-danger);
}

.botao-upload-image:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
