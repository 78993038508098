<script setup>
import { computed, ref, watchEffect } from "vue";
import store from "@/store";
import Multiselect from "@vueform/multiselect/src/Multiselect";

const categorias = computed(() =>
  store.getters["categoriaModule/getData"].map((categoria) => categoria.nome)
);

const categoriaSelecionada = ref("");

watchEffect(() => {
  if (categoriaSelecionada.value) {
    store.commit(
      "categoriaModule/setCategoriaSelecionada",
      categoriaSelecionada.value
    );
  }
  if (categoriaSelecionada.value == null) {
    store.commit("categoriaModule/setCategoriaSelecionada", null);
  }
});
</script>

<template>
  <div class="field">
    <div class="select-categoria">
      <div class="campo-select">
        <Multiselect
          placeholder="Filtrar por categoria"
          v-model="categoriaSelecionada"
          :options="categorias"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.select-categoria {
  display: flex;
  margin-left: 1rem;
  width: 100%;

  .label-select {
    color: var(--cor-principal);
    font-size: 1rem;
    font-weight: bold;
  }

  .campo-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin-right: 2rem;
    margin-bottom: -0.8rem;

    .multiselect {
    font-size: 12px !important;
    width: 100%;
    }
}
}
</style>
