import store from "@/store"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import {
  faAddressBook,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faAsterisk,
  faAt,
  faBagShopping,
  faCheck,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faEnvelope,
  faGear,
  faGift,
  faGlobe,
  faGraduationCap,
  faHeartPulse,
  faHouseUser,
  faLocationDot,
  faMagnifyingGlass,
  faMusic,
  faPaperclip,
  faPaw,
  faPenToSquare,
  faPlus,
  faShirt,
  faStarOfLife,
  faTrash,
  faUpload,
  faUserGear,
  faUtensils,
  faWrench,
  faX,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import Keycloak from "keycloak-js"
import { createApp } from "vue"
import VueTheMask from "vue-the-mask"
import Toast from "vue-toastification"
import App from "./App.vue"
import router from "./router"

library.add(
  faUtensils,
  faBagShopping,
  faHeartPulse,
  faPaw,
  faMusic,
  faGraduationCap,
  faShirt,
  faPaperclip,
  faWrench,
  faMagnifyingGlass,
  faGift,
  faCircle,
  faAddressBook,
  faLocationDot,
  faCircleInfo,
  faCheck,
  faAsterisk,
  faGear,
  faArrowRightFromBracket,
  faStarOfLife,
  faTrash,
  faWhatsapp,
  faEnvelope,
  faGlobe,
  faAt,
  faPenToSquare,
  faTrash,
  faPlus,
  faCircleCheck,
  faX,
  faArrowLeft,
  faArrowRight,
  faUpload,
  faUserGear,
  faHouseUser

)

function createAppVue() {
  createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(VueTheMask)
    .use(Toast)
    .mount("#app")

}

const initOptions = {
  url: process.env.VUE_APP_CONTAS_URL,
  realm: process.env.VUE_APP_CONTAS_REALM,
  clientId: process.env.VUE_APP_CONTAS_CLIENTID,
}

const keyCloack = new Keycloak(initOptions)

export const logout = () => {
  keyCloack.logout()
}

keyCloack
  .init({
    onLoad: "login-required",
    checkLoginIframe: false,
  })
  .then((auth) => {
    const papeis = keyCloack.realmAccess.roles
    const papeisGestaoCDD = JSON.parse(process.env.VUE_APP_CDD_PAPEIS_GESTAO_RH)
    const usuarioGestorLogado = papeisGestaoCDD.every(item => papeis.map(role => role.toLowerCase()).includes(item.toLowerCase()))

    if (!auth) {
      window.location.reload()
    } else if (usuarioGestorLogado) {
      createAppVue()
      store.commit("authModule/setRoleAdmin", true)
      store.commit("authModule/changeToken", keyCloack.token)
      store.commit("authModule/changeRole", keyCloack.realmAccess.roles)
      store.commit("authModule/changeName", keyCloack.idTokenParsed.given_name)
      store.commit(
        "authModule/changeUser",
        keyCloack.idTokenParsed.preferred_username
      )

      setInterval(() => {
        keyCloack
          .updateToken(180)
          .then((refreshed) => {
            if (refreshed)
              store.commit("authModule/changeToken", keyCloack.token)
          })
          .catch(() => { })
      }, 10000)

    } else {
      createAppVue()
      store.commit("authModule/setRoleAdmin", false)
      store.commit("authModule/changeToken", keyCloack.token)
      store.commit("authModule/changeRole", keyCloack.realmAccess.roles)
      store.commit("authModule/changeName", keyCloack.idTokenParsed.given_name)
      store.commit(
        "authModule/changeUser",
        keyCloack.idTokenParsed.preferred_username
      )

      setInterval(() => {
        keyCloack
          .updateToken(180)
          .then((refreshed) => {
            if (refreshed)
              store.commit("authModule/changeToken", keyCloack.token)
          })
          .catch(() => { })
      }, 10000)
    }
  })