import store from "@/store";

export function pegarPrimeiraAbrangencia(abrangencias) {
  const palavras = abrangencias.split(",");

  const primeiraAbrangencia = palavras[0].trim();

  const temVirgula = palavras.length > 1;

  return temVirgula ? `${primeiraAbrangencia} e outros` : primeiraAbrangencia;
}

export function getCategoriaByNome(nome) {
  const categoriaEncontrada = store.getters["categoriaModule/getData"].filter(
    (categoria) => categoria.nome == nome
  );
  return categoriaEncontrada.length > 0 ? categoriaEncontrada[0].id : "";
}

export function getAbrangenciasByLocais(locais) {
  let abrangenciasEncontradas = [];

  locais.map((local) => {
    const abrangenciaEncontrada = store.getters[
      "abrangenciaModule/getData"
    ].filter((abrangencia) => abrangencia.local == local);
    abrangenciasEncontradas.push({ id: abrangenciaEncontrada[0].id });
  });
  return abrangenciasEncontradas;
}

export function transformaData(data) {
  
  let dataFormatada = null;

  if(data){
    const [ano, mes, dia] = data ? data.split("-") : null;
    dataFormatada = `${dia}/${mes}/${ano}`;
  }

  return dataFormatada;
}

const limiteTexto = 25;
const limiteTitulo = 25;

export function resumeOTexto(data) {
  let textoResumido;

  if (data.length > limiteTexto) {
    textoResumido = data.slice(0, limiteTexto) + "...";
  } else {
    textoResumido = data;
  }
  return textoResumido;
}

export function resumeOTitulo(data) {
  let textoResumido;

  if (data.length > limiteTitulo) {
    textoResumido = data.slice(0, limiteTitulo) + "...";
  } else {
    textoResumido = data;
  }
  return textoResumido;
}

export function separarAbrangencias(array) {

  const abrangenciasSeparadas = array[0].split(', ');

  return abrangenciasSeparadas;

}