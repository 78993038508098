<script setup>
import { ref, defineProps, computed} from "vue";
import store from "@/store";
import {
  pegarPrimeiraAbrangencia,
  resumeOTexto,
  resumeOTitulo,
} from "@/utils/utils";

const props = defineProps({
  parceiroSelecionado: Object,
  imagem: String,
  estabelecimento: String,
  categoria: String,
  anuncio: String,
  abrangencia: String,
});
const modal = ref("");

function abrirInfoEstabelecimentoModal(card) {
  store.commit("parceiroModule/setParceiroSelecionado", card);
  modal.value = document
    .querySelector("#modal-infoEstabelecimento")
    .classList.add("is-active");
}
const imagem = computed(()=> 'data:image/png;base64,' + props.imagem)

</script>

<template>
  <div class="card">
    <div class="card-image">
      <button class="botao-imagem" @click="abrirInfoEstabelecimentoModal(props.parceiroSelecionado)">
      <figure class="image is-rounded">
        <img
          class="is-rounded has-background-white"
          :src="imagem"
          alt="logo"
        />
      </figure>
      </button>
    </div>
    <div class="card-content">
      <p class="texto-estabelecimento">
        {{ resumeOTitulo(props.estabelecimento) }}
      </p>
      <span class="texto-icone">
        <font-awesome-icon
          class="small-icon"
          icon="fa-solid fa-graduation-cap"
        />
        <p>
          {{ props.categoria }}
        </p>
      </span>
      <span class="texto-icone">
        <font-awesome-icon class="small-icon" icon="fa-solid fa-gift" />
        <p class="anuncio">
          {{ resumeOTexto(props.anuncio) }}
        </p>
      </span>
      <span class="texto-icone">
        <font-awesome-icon class="extra-small-icon" icon="fa-solid fa-circle" />
        <p class="abrangencia">
          {{ pegarPrimeiraAbrangencia(props.abrangencia) }}
        </p>
      </span>
    </div>
    <footer class="card-footer-item">
      <button @click="abrirInfoEstabelecimentoModal(props.parceiroSelecionado)">
        VER DETALHES
      </button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.card {
  width: 100%;
  border-radius: 10px !important;
  margin: 1.2rem 0 0.7rem 0;
  transition: all 0.2s;
}

.card:hover {
  box-shadow: var(--cor-contraste) 0px 5px 20px;

  img {
    border: 4px solid var(--cor-contraste);
  }
}

.botao-imagem {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.card-image {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin-top: 0.5rem;

  img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border: 4px solid var(--cor-contraste);

    @media screen and (max-width: 476px) {
      height: 80px;
      width: 80px;
    }

    @media screen and (max-width: 434px) {
      height: 75px;
      width: 75px;
    }

    @media screen and (max-width: 418px) {
      height: 70px;
      width: 70px;
    }

    @media screen and (max-width: 402px) {
      height: 65px;
      width: 65px;
    }

    @media screen and (max-width: 318px) {
      height: 50px;
      width: 50px;
    }
  }
}

.card-image:first-child {
  img {
    border-radius: 50%;
  }
}

.card-content {
  padding: 2rem 1.8rem 0rem 1.8rem;
  position: relative;
}

.texto-estabelecimento {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-bold);
  color: var(--cor-texto);
  font-size: 1rem;
  margin-top: 35px;

  @media screen and (max-width: 476px) {
    margin-top: 16px;
    font-size: 0.75rem;
  }

  @media screen and (max-width: 434px) {
    margin-top: 16px;
    font-size: 0.65rem;
  }

  @media screen and (max-width: 418px) {
    margin-top: 16px;
    font-size: 0.6rem;
  }

  @media screen and (max-width: 402px) {
    margin-top: 16px;
    font-size: 0.65rem;
  }

  @media screen and (max-width: 318px) {
    margin-top: 5px;
    font-size: 0.5rem;
  }
}

.texto-icone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.45rem;
  
  p {
    color: var(--cor-texto);
    font-size: 0.9rem;
    margin-left: 5px;

    @media screen and (max-width: 600px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.65rem;
    }

    @media screen and (max-width: 434px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 418px) {
      font-size: 0.55rem;
    }

    @media screen and (max-width: 402px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 318px) {
      font-size: 0.4rem;
    }
  }
  .anuncio {
    color: #0eab31;
  }
  .abrangencia {
    font-size: 0.8rem;

    @media screen and (max-width: 600px) {
      font-size: 0.75rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.65rem;
    }

    @media screen and (max-width: 434px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 418px) {
      font-size: 0.55rem;
    }

    @media screen and (max-width: 402px) {
      font-size: 0.5rem;
    }

    @media screen and (max-width: 318px) {
      font-size: 0.35rem;
    }
  }
  .small-icon {
    font-size: 13px;

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }

    @media screen and (max-width: 476px) {
      font-size: 11px;
    }

    @media screen and (max-width: 434px) {
      font-size: 10px;
    }

    @media screen and (max-width: 418px) {
      font-size: 9px;
    }

    @media screen and (max-width: 402px) {
      font-size: 8px;
    }

    @media screen and (max-width: 318px) {
      font-size: 7px;
    }
  }
  .extra-small-icon {
    font-size: 5px;

    @media screen and (max-width: 476px) {
      font-size: 4px;
    }
    @media screen and (max-width: 378px) {
      font-size: 3px;
    }
  }
}
.card-footer-item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.75rem;

  button {
    color: var(--cor-principal);
    font-weight: var(--font-bold);
    font-size: 0.7rem;
    border: none;
    background: none;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      font-size: 0.7rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.65rem;
    }

    @media screen and (max-width: 434px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 418px) {
      font-size: 0.55rem;
    }

    @media screen and (max-width: 402px) {
      font-size: 0.5rem;
    }

    @media screen and (max-width: 318px) {
      font-size: 0.35rem;
    }
  }
}
</style>
