export default {
    namespaced: true,
    state: {
      user: "",
      name: "",
      role: {},
      token: "",
      isAdmin: null,
      roleAdmin: null,
    },
    getters: {
      getUser(state) {
        return state.user;
      },
      getToken(state) {
        return state.token;
      },
      getIsAdmin(state){
        return state.isAdmin;
      },
      getRoleAdmin(state){
        return state.roleAdmin;
      },
    },
    mutations: {
      changeUser(state, payload) {
        state.user = payload;
      },
      changeName(state, payload) {
        state.name = payload;
      },
      changeRole(state, payload) {
        state.role = payload;
      },
      changeToken(state, payload) {
        state.token = payload;
      },
      setIsAdmin(state, payload){
        state.isAdmin = payload;
      },
      setRoleAdmin(state, payload){
        state.roleAdmin = payload;
      }
    },
    actions: {},
    modules: {},
  };
