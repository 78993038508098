export default {
  namespaced: true,
  state: {
    beneficios: [],
  },
  getters: {
    getData(state) {
      return state.beneficios;
    },
  },
  mutations: {
    setData(state, payload) {
      state.beneficios = payload;
    },
    editBeneficio(state, payload) {
      state.beneficios[payload.index] = payload.data;
    },
    deleteBeneficio(state, id) {
      state.beneficios = state.beneficios.filter((item) => item.id != id);
    },
  },
};
