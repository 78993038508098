export default {
  namespaced: true,
  state: {
    idParceiro: "",
    parceiroSelecionado: [],
    parceiroSelecionadoEdit: {
      nome: "",
      imagem: "",
      categoria: "",
      contato: {
        telefone: "",
        whatsapp: "",
        email: "",
        rede: "",
      },
      endereco: {
        cep: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        complemento: "",
      },
      beneficio: {
        anuncio: "",
        abrangencia: [],
        comprovacao: "",
        observacao: "",
        inicioContrato: "",
        fimContrato: "",
        status: null
      },
    },
    parceirosPaginados: [],
    parceiros: [],
    loading: true,
    parceirosPorAbrangencia: [],
    totalPaginas: 0,
    paginaAtual: 1,
    loadingMore: false,
    disableInput: false
  },
  getters: {
    getParceirosPaginados(state) {
      return state.parceirosPaginados;
    },
    getTodosOsParceiros(state) {
      return state.parceiros;
    },
    getParceiroSelecionado(state) {
      return state.parceiroSelecionado;
    },
    getParceiroSelecionadoEdit(state) {
        return state.parceiroSelecionadoEdit;
    },
    getFiltrarPorAbrangencia(state){
      return state.parceirosPorAbrangencia;
    },
    getIdParceiro(state){
      return state.idParceiro;
    },
    getLoading(state) {
      return state.loading;
    },
    getTotalPaginas(state) {
      return state.totalPaginas;
    },
    getPaginaAtual(state) {
      return state.paginaAtual;
    },
    getLoadingMore(state) {
      return state.loadingMore;
    },
    isInputDisabled(state) {
      return state.disableInput;
    }

  },
  mutations: {
    setParceirosPaginados(state, payload) {
      state.parceirosPaginados.push(...payload);
    },
    setTodosOsParceiros(state, payload) {
      state.parceiros = payload;
    },
    setParceiroSelecionado(state, payload) {
      state.parceiroSelecionado = payload;
    },
    setParceiroSelecionadoEdit(state, payload) {
      state.parceiroSelecionadoEdit = payload;
    },
    setFiltrarPorAbrangencia(state, payload){
      state.parceirosPorAbrangencia = payload;
    },
    editParceiro(state, payload) {
      state.parceiros[payload.indexParceiros] = payload.data;
    },
    editParceiroPorAbrangencia(state, payload) {
      state.parceirosPorAbrangencia[payload.indexPorAbrangencia] = payload.data;
    },
    deleteParceiro(state, id) {
      state.parceiros = state.parceiros.filter((item) => item.id != id);
    },
    setIdParceiro(state, payload){
      state.idParceiro = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setTotalPaginas(state, payload) {
      state.totalPaginas = payload;
    },
    setPaginaAtual(state, payload) {
      state.paginaAtual = payload;
    },
    setLoadingMore(state, payload) {
      state.loadingMore = payload;
    },
    setInputDisabled(state, payload) {
      state.disableInput = payload;
    },
    resetParceirosPaginados(state, payload) {
      state.parceirosPaginados = payload;
    }
  },
};
