<script setup>
import { onMounted, onBeforeMount, ref, computed, watchEffect } from "vue";
import { fecharModal } from "@/utils/modalConfig";
import toast from '@/utils/toastConfig';
import store from "@/store";
import BotaoCadastrar from "@/components/BotaoCadastrar.vue";
import CategoriaAPI from "@/services/CategoriaAPI";
import { cloneDeep } from "lodash";
import ParceiroAPI from "@/services/ParceiroAPI";

const categorias = computed(() => store.getters["categoriaModule/getData"]);
const categoriasEditaveis = computed(() => cloneDeep(categorias.value));
const criandoCategoria = ref(false);
const editing = ref({});
const modal = ref("");
const novaCategoria = ref("");
const idCategoria = ref("");

function cancelarEditar(categoria) {
  categoriasEditaveis.value.forEach((c) => {
    if (categoria.id == c.id) {
      const categoriaIgual = categorias.value.find(
        (cI) => cI.id == categoria.id
      );
      c.nome = categoriaIgual.nome;
    }
  });
  document.getElementById(categoria.id).disabled = true;
  editing.value[categoria.id] = false;
}

function comecarEditar(categoria) {
  
  document.getElementById(categoria.id).disabled = false;
  editing.value[categoria.id] = true;
  novaCategoria.value = "";
  criandoCategoria.value = false;
  document.getElementById("cadastrar").disabled = true;
  

  if (idCategoria.value != "" && idCategoria.value != categoria.id && document.getElementById(idCategoria.value)) {
    document.getElementById(idCategoria.value).disabled = true;
    editing.value[idCategoria.value] = false;
  }

  categoriasEditaveis.value.forEach((categoria) => {
    if (categoria.id == idCategoria.value) {
      const categoriaIgual = categorias.value.find(
        (categoria) => categoria.id == idCategoria.value
      );
      categoria.nome = categoriaIgual.nome;
    }
  });

  idCategoria.value = categoria.id;
}

function fecharModalEDesabilitarInput(modal) {
  fecharModal(modal);
  novaCategoria.value = "";
  criandoCategoria.value = false;
  document.getElementById("cadastrar").disabled = true;
  if (document.getElementById(idCategoria.value)) {
    document.getElementById(idCategoria.value).disabled = true;
  }

  categoriasEditaveis.value.forEach((categoria) => {
    if (categoria.id == idCategoria.value) {
      const categoriaIgual = categorias.value.find(
        (categoria) => categoria.id == idCategoria.value
      );
      categoria.nome = categoriaIgual.nome;
    }
  });

  editing.value[idCategoria.value] = false;
}

function liberarInput() {
  document.getElementById("cadastrar").disabled =
    !document.getElementById("cadastrar").disabled;
  criandoCategoria.value = !criandoCategoria.value;
  novaCategoria.value = "";
  
  if (idCategoria.value != "" && document.getElementById(idCategoria.value)) {
    document.getElementById(idCategoria.value).disabled = true;
    editing.value[idCategoria.value] = false;
  }

  categoriasEditaveis.value.forEach((categoria) => {
    if (categoria.id == idCategoria.value) {
      const categoriaIgual = categorias.value.find(
        (categoria) => categoria.id == idCategoria.value
      );
      categoria.nome = categoriaIgual.nome;
    }
  });
}

function criarCategoria(categoria) {
  CategoriaAPI.postCategoria(categoria).then(() => {
    toast.abrirToast('success', "Categoria cadastrada com sucesso!");
  }).catch((error)=> {
    toast.abrirToast('error', error);
  });
  criandoCategoria.value = !criandoCategoria.value;
  novaCategoria.value = "";
  document.getElementById("cadastrar").disabled = !document.getElementById("cadastrar").disabled;
}

function editarCategoria(categoria) {
  CategoriaAPI.editCategoria(categoria).then(() => {
    toast.abrirToast('success', "Categoria editada com sucesso!");
  }).catch((error) => {
    toast.abrirToast('error', error);
  })
  editing.value[categoria.id] = false;
  document.getElementById(categoria.id).disabled = true;
}

function deletarCategoria(id) {
  CategoriaAPI.deleteCategoria(id).then(() => {
    toast.abrirToast('success', "Categoria deletada com sucesso!");
  }).catch((error) => {
    toast.abrirToast('error', error);
  });
  novaCategoria.value = "";
  criandoCategoria.value = false;
  document.getElementById("cadastrar").disabled = true;
  if (document.getElementById(idCategoria.value)){
    document.getElementById(idCategoria.value).disabled = true;
  }

  categoriasEditaveis.value.forEach((categoria) => {
    if (categoria.id == idCategoria.value) {
      const categoriaIgual = categorias.value.find(
        (categoria) => categoria.id == idCategoria.value
      );
      categoria.nome = categoriaIgual.nome;
    }
  });

  editing.value[idCategoria.value] = false;
}

onMounted(() => {
  modal.value = document.querySelector("#modal-categoria");
});

onBeforeMount(() => {
  CategoriaAPI.getCategorias();
});

watchEffect(()=>{
  if(categoriasEditaveis.value){
    ParceiroAPI.getParceirosPaginados();
  }
})
</script>

<template>
  <div class="modal" id="modal-categoria">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Gerenciar Categorias</p>
        <button
          class="delete"
          aria-label="close"
          @click="fecharModalEDesabilitarInput(modal)"
        ></button>
      </header>
      <section class="modal-card-body">
        <span class="botao">
          <BotaoCadastrar texto-botao="CADASTRAR" @click="liberarInput()" />
        </span>

        <span v-show="criandoCategoria" class="input-icone">
          <input
            autocomplete="off"
            disabled
            type="text"
            class="inputCadastrar"
            id="cadastrar"
            v-model="novaCategoria"
            @keyup.enter="criarCategoria(novaCategoria)"
          />
          <font-awesome-icon
            icon="fa-solid fa-plus"
            @click="criarCategoria(novaCategoria)"
            class="icone-cadastrar-categoria"
          />
        </span>
        <div class="table-box">
          <table class="table is-hoverable is-fullwidth">
            <thead class="table-header">
              <tr>
                <th>Categoria</th>
                <th class="icone-column">Editar</th>
                <th class="icone-column">Excluir</th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr v-for="(categoria, index) in categorias" :key="categoria.id">
                <td>
                  <input
                    autocomplete="off"
                    disabled
                    type="text"
                    class="inputTd"
                    v-model="categoriasEditaveis[index].nome"
                    :id="categoria.id"
                  />
                </td>
                <td class="icone-column">
                  <span
                    class="custom--revert"
                    data-cooltipz-dir="left"
                    aria-label="Editar"
                  >
                    <font-awesome-icon
                      v-if="!editing[categoria.id]"
                      icon="fa-solid fa-pen-to-square"
                      @click="comecarEditar(categoria)"
                      class="icone"
                    />

                    <span class="editarCancelar" v-else>
                      <font-awesome-icon
                        icon="fa-solid fa-circle-check"
                        @click="editarCategoria(categoriasEditaveis[index])"
                        class="icone"
                      />

                      <font-awesome-icon
                        icon="fa-solid fa-x"
                        @click="cancelarEditar(categoria)"
                        class="icone iconeCancelar"
                      />
                    </span>
                  </span>
                </td>
                <td class="icone-column">
                  <span
                    class="custom--revert"
                    data-cooltipz-dir="left"
                    aria-label="Excluir"
                  >
                    <font-awesome-icon
                      @click="deletarCategoria(categoria.id)"
                      icon="fa-solid fa-trash"
                      class="icone"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-card {
  border-radius: 5px;
  

  .modal-card-head {
    background-color: var(--cor-background);
    border: none;

    .modal-card-title {
      color: var(--cor-principal);
      font-weight: var(--font-medium);
      text-align: center;
      margin-top: 0.7rem;
    }
  }

  .modal-card-body {
    height: 31rem;
    background-color: var(--cor-background);
    padding-top: 0.2rem;

    @media screen and (max-width: 1366px) {
      height: 27rem;
    }

    @media screen and (max-width: 1280px) {
      height: 25rem;
    }

    .input-icone {
      display: flex;
      margin-top: 1rem;
      gap: 1rem;

      .inputCadastrar {
        background-color: inherit;
        color: inherit;
        font-size: 1rem;
        font-weight: inherit;
        font-family: inherit;
        border: 0px solid transparent;
      }

      .inputCadastrar:not(:disabled) {
        border-bottom: 2px solid var(--cor-principal);
        width: 60%;
      }

      .inputCadastrar:not(:disabled):focus-visible {
        outline: none;
      }

      .icone-cadastrar-categoria {
        cursor: pointer;
      }
    }

    .table-box {
      overflow-x: auto;
      margin-top: 0.7rem;

      table {
        background-color: transparent;

        th {
          color: var(--cor-principal) !important;
          font-size: 1.2rem;
          padding: 12px 15px;
        }

        td {
          padding: 1rem;
        }

        .text-column {
          text-align: center;
        }

        .inputTd {
          background-color: inherit;
          color: inherit;
          font-size: 1rem;
          font-weight: inherit;
          font-family: inherit;
          border: 0px solid transparent;
        }

        .inputTd:not(:disabled) {
          border-bottom: 2px solid var(--cor-principal);
          width: 80%;
        }

        .inputTd:not(:disabled):focus-visible {
          outline: none;
        }

        .icone-column {
          text-align: center;

          .editarCancelar {
            display: flex;
            gap: 1.5rem;
            justify-content: center;
            margin-top: 0.2rem;
          }

          .icone {
            cursor: pointer;

            .iconeCancelar {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
