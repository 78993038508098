export default {
    namespaced: true,
    state:{
        categoriaSelecionada: null,
        categorias: []
    },
    getters: {
        getData(state){
            return state.categorias;
        },
        getTeste(state){
            return state.teste;
        },
        getCategoriaSelecionada(state){
            return state.categoriaSelecionada;
        }
    },
    mutations: {
        setData(state, payload){
            state.categorias = payload;
        },
        setTeste(state, payload) {
            state.teste = payload;
        },
        setCategoriaSelecionada(state, payload){
            return state.categoriaSelecionada = payload;
        },
        postCategoria(state, payload) {
            state.categorias.push(payload);
        }
        ,
        editCategoria(state, payload) {
            state.categorias[payload.index] = payload.data;
        },
        deleteCategoria(state, id) {
            state.categorias = state.categorias.filter((item) => item.id != id);
        }
    }
}