<template>
    <v-row class="loading">
      <div class="c-loader">
      </div>
    </v-row>
    <h3>Carregando...</h3>
  </template>
  
  <style scoped>
  .c-loader {
    align-self: center;
    display: flex;
    justify-content: center;
    width: 65px;
    height: 65px;
    margin: 5px;
    border: 6px solid var(--cor-icone);
    border-radius: 50%;
    border-top-color: var(--cor-principal);
    animation: is-rotating 1s infinite;
  }
  
  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>