<script setup>
import store from "@/store";
import { computed } from "vue";
import { logout } from "@/main";

const user = computed(() => store.getters["authModule/getUser"]);
const isAdmin = computed(() => store.getters["authModule/getIsAdmin"]);
const roleAdmin = computed(() => store.getters["authModule/getRoleAdmin"]);

function abrirPainelAdmin() {
  store.commit("authModule/setIsAdmin", !isAdmin.value);
  store.commit("categoriaModule/setCategoriaSelecionada", null);
}

function limparCategoria() {
  store.commit("categoriaModule/setCategoriaSelecionada", null);
}
</script>

<template>
  <header>
    <nav>
      <a href="#" class="logo-container">
        <img src="@/assets/logo_alterdata_small.svg" alt="Logo da Alterdata" />
        <div class="divisoria"></div>
        <p @click="limparCategoria()">Clube de Descontos</p>
      </a>
      <span class="login">
        <p>Olá, {{ user }}</p>
        <a class="botao-admin" v-if="roleAdmin" @click="abrirPainelAdmin"
          ><font-awesome-icon
            data-tooltip
            title="Acessar dashboard"
            v-if="isAdmin"
            class="small-icon"
            icon="fa-solid fa-house-user"
          />
          <font-awesome-icon
            v-else
            data-tooltip
            title="Acessar área administrativa"
            class="small-icon"
            icon="fa-solid fa-user-gear"
          />
        </a>
        <a @click="logout"
          ><font-awesome-icon
            data-tooltip
            title="Sair"
            class="small-icon"
            icon="fa-solid fa-arrow-right-from-bracket"
        /></a>
      </span>
    </nav>
  </header>
</template>

<style lang="scss">
header {
  background-color: var(--cor-secundaria);

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1.2rem 1rem;

    @media screen and (max-width: 476px) {
      padding: 1rem 1rem 0rem 1rem;
    }

    .logo-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 3rem;
        height: auto;

        @media screen and (max-width: 600px) {
          width: 2rem;
        }

        @media screen and (max-width: 476px) {
          width: 1.5rem;
        }

        @media screen and (max-width: 402px) {
          font-size: 1.1rem;
        }

        @media screen and (max-width: 386px) {
          width: 1rem;
        }
      }
      .divisoria {
        width: 4.5px;
        height: 35px;
        background-color: var(--cor-principal);
        margin: 0 1rem;

        @media screen and (max-width: 600px) {
          height: 30px;
        }

        @media screen and (max-width: 476px) {
          height: 20px;
        }
        @media screen and (max-width: 386px) {
          margin: 0 0.3rem;
          height: 13px;
        }
      }
      p {
        color: var(--cor-principal);
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: var(--font-bold);

        @media screen and (max-width: 600px) {
          font-size: 1.1rem;
        }

        @media screen and (max-width: 476px) {
          font-size: 1.1rem;
        }

        @media screen and (max-width: 434px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 418px) {
          font-size: 0.9rem;
        }

        @media screen and (max-width: 402px) {
          font-size: 0.7rem;
        }

        @media screen and (max-width: 318px) {
          font-size: 0.6rem;
        }
      }
    }

    .login {
      display: flex;
      align-items: center;
      color: var(--cor-principal);
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: var(--font-bold);
      padding: 0.5rem 1rem;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 476px) {
        font-size: 0.65rem;
      }

      @media screen and (max-width: 434px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 418px) {
        font-size: 0.55rem;
      }

      @media screen and (max-width: 402px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 318px) {
        font-size: 0.4rem;
      }

      .botao-admin {
        @media screen and (max-width: 781px) {
          display: none;
        }
      }
    }

    .small-icon {
      color: var(--cor-principal);
      text-decoration: none;
      font-size: 1.4rem;
      padding-left: 1rem;
      cursor: pointer;
      transition: transform 0.1s ease-in-out;

      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 476px) {
        font-size: 0.8rem;
      }
      @media screen and (max-width: 434px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 418px) {
        font-size: 0.55rem;
      }

      @media screen and (max-width: 402px) {
        font-size: 0.6rem;
      }
    }

    .small-icon:hover {
      transform: scale(1.09);
    }
  }
}
</style>
