import store from "@/store";
import http from "./config";
import { computed } from "vue";
import { getAbrangenciasByLocais, getCategoriaByNome } from "@/utils/utils";

const parceiros = computed(() => store.getters["parceiroModule/getTodosOsParceiros"]);
const abrangenciaSelecionada = computed(
  () => store.getters["abrangenciaModule/getAbrangenciaSelecionada"]
);
const parceirosPorAbrangencia = computed(
  () => store.getters["parceiroModule/getFiltrarPorAbrangencia"]
);

const ParceiroAPI = {
  async getTodosParceiros() {
    return http
      .get("/parceiro")
      .then((response) => {
        store.commit("parceiroModule/setTodosOsParceiros", response.data);
        store.commit("setErros", "");
        store.commit("parceiroModule/setLoading", false);
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },
  async getParceirosPaginados(page) {
    if (typeof page !== 'number' || isNaN(page) || page <= 0) {
      return
    }
    return http
      .get(`/parceiro/paginado?pagina=${page}`)
      .then((response) => {
        store.commit("parceiroModule/setParceirosPaginados", response.data.content);
        store.commit("setErros", "");
        store.commit("parceiroModule/setLoading", false);
        store.commit("parceiroModule/setTotalPaginas", response.data.totalPages);
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },

  async findByAbrangencia(local) {
    return http
      .get("/parceiro/local", {
        params: {
          local: local,
        },
      })
      .then((response) => {
        store.commit("parceiroModule/setLoading", false);
        store.commit("parceiroModule/setFiltrarPorAbrangencia", response.data);
        store.commit("setErros", "");
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },
  async postParceiro(parceiro, imagem) {
    const data = new FormData();
    data.append("imagem", imagem, { type: "multipart/form-data" });

    const obj = {
      nome: parceiro.nome,
      categoria: {
        id: parceiro.categoria,
      },
      contato: {
        telefone: parceiro.contato.telefone,
        whatsapp: parceiro.contato.whatsapp,
        email: parceiro.contato.email,
        rede: parceiro.contato.rede,
      },
      endereco: {
        cep: parceiro.endereco.cep,
        logradouro: parceiro.endereco.logradouro,
        numero: parceiro.endereco.numero,
        bairro: parceiro.endereco.bairro,
        cidade: parceiro.endereco.cidade,
        uf: parceiro.endereco.uf,
        complemento: parceiro.endereco.complemento,
      },
      beneficio: {
        anuncio: parceiro.beneficio.anuncio,
        abrangencia: parceiro.beneficio.abrangencia,
        comprovacao: parceiro.beneficio.comprovacao,
        observacao: parceiro.beneficio.observacao,
        inicioContrato: parceiro.beneficio.inicioContrato,
        fimContrato: parceiro.beneficio.fimContrato,
      },
    };

    const json = JSON.stringify(obj);
    const blob = new Blob([json], { type: "application/json" });
    data.append("parceiro", blob);

    return await http
      .post("/parceiro", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then( async (response) => {
        if (abrangenciaSelecionada.value) {
          response.data.beneficio.abrangencia.forEach((obj) => {
            if (obj.local.includes(abrangenciaSelecionada.value)) {
              store.commit(
                "parceiroModule/setFiltrarPorAbrangencia",
                parceirosPorAbrangencia.value.concat(response.data)
              );
            }
            store.commit(
              "parceiroModule/setTodosOsParceiros",
              parceiros.value?.concat(response.data)
            );
          });
        }
        if (!abrangenciaSelecionada.value) {
          store.commit(
            "parceiroModule/setTodosOsParceiros",
            parceiros.value.concat(response.data)
          );
        }
        store.commit("parceiroModule/resetParceirosPaginados", []);
        store.commit("parceiroModule/setPaginaAtual", 1);
        await this.getParceirosPaginados(store.getters["parceiroModule/getPaginaAtual"]);

        store.commit("setErros", "");
        store.commit("parceiroModule/setLoading", false);
        return response;
        }).catch((error) => { 
          throw error.response.data.errors[0];
        });
  },
  async editParceiro(dados) {
    return http
      .put(`/parceiro/${dados.id}`, {
        nome: dados.nome,
        categoria: {
          id: getCategoriaByNome(dados.categoria),
        },
        contato: {
          telefone: dados.contato.telefone,
          whatsapp: dados.contato.whatsapp,
          email: dados.contato.email,
          rede: dados.contato.rede,
        },
        endereco: {
          cep: dados.endereco.cep,
          logradouro: dados.endereco.logradouro,
          numero: dados.endereco.numero,
          bairro: dados.endereco.bairro,
          cidade: dados.endereco.cidade,
          uf: dados.endereco.uf,
          complemento: dados.endereco.complemento,
        },
        beneficio: {
          anuncio: dados.beneficio.anuncio,
          abrangencia: getAbrangenciasByLocais(dados.beneficio.abrangencia),
          comprovacao: dados.beneficio.comprovacao,
          observacao: dados.beneficio.observacao,
          inicioContrato: dados.beneficio.inicioContrato,
          fimContrato: dados.beneficio.fimContrato,
        },
      })
      .then( async (response) => {
        const indexParceiros = parceiros.value?.indexOf(
          parceiros.value.filter((parceiro) => parceiro.id == dados.id)[0]
        );

        const indexPorAbrangencia = parceirosPorAbrangencia.value.indexOf(
          parceirosPorAbrangencia.value.filter(
            (parceiro) => parceiro.id == dados.id
          )[0]
        );

        if (abrangenciaSelecionada.value) {
          let abrangenciaEncontrada = false;

          response.data.beneficio.abrangencia.forEach((obj) => {
            if (obj.local.includes(abrangenciaSelecionada.value)) {
              abrangenciaEncontrada = true;
              store.commit("parceiroModule/editParceiroPorAbrangencia", {
                indexPorAbrangencia,
                data: response.data,
              });
              store.commit("parceiroModule/editParceiro", {
                indexParceiros,
                data: response.data,
              });
            }
          });

          if (!abrangenciaEncontrada) {
            if (indexPorAbrangencia !== -1) {
              parceirosPorAbrangencia.value.splice(indexPorAbrangencia, 1);
              store.commit(
                "parceiroModule/setFiltrarPorAbrangencia",
                parceirosPorAbrangencia.value
              );
            }
          }
        }

        if (!abrangenciaSelecionada.value) {
          store.commit("parceiroModule/editParceiro", {
            indexParceiros,
            data: response.data,
          });
        }
        store.commit("parceiroModule/resetParceirosPaginados", []);
        store.commit("parceiroModule/setPaginaAtual", 1);
        await this.getParceirosPaginados(store.getters["parceiroModule/getPaginaAtual"]);

        store.commit("setErros", "");
        store.commit("parceiroModule/setLoading", false);
        }).catch((error) => { 
          throw error.response.data.errors[0];
        });
  },

  async editImagem(dados, imagem) {
    const data = new FormData();
    data.append("imagem", imagem, { type: "multipart/form-data" });

    return await http
      .put(`/parceiro/imagem/${dados.id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then( async (response) => {
        if (abrangenciaSelecionada.value) {
          const indexPorAbrangencia = parceirosPorAbrangencia.value.indexOf(
            parceirosPorAbrangencia.value.filter(
              (parceiro) => parceiro.id == dados.id
            )[0]
          );
          const parceiroAbrangencia =
            parceirosPorAbrangencia.value[indexPorAbrangencia];
          parceiroAbrangencia.imagem = response.data;

          store.commit("parceiroModule/editParceiroPorAbrangencia", {
            indexPorAbrangencia,
            data: parceiroAbrangencia,
          });
        }

        const indexParceiros = parceiros.value?.indexOf(
          parceiros.value.filter((parceiro) => parceiro.id == dados.id)[0]
        );
        const parceiro = parceiros.value[indexParceiros];
        parceiro.imagem = response.data;

        store.commit("parceiroModule/editParceiro", {
          indexParceiros,
          data: parceiro,
        });
        store.commit("parceiroModule/resetParceirosPaginados", []);
        store.commit("parceiroModule/setPaginaAtual", 1);
        await this.getParceirosPaginados(store.getters["parceiroModule/getPaginaAtual"]);

        store.commit("setErros", "");
        store.commit("parceiroModule/setLoading", false);
      })
      .catch((error) => {
        throw error.response.data.errors[0];
      });
  },

  async deleteParceiro(id) {
    return http
      .delete(`/parceiro/${id}`)
      .then( async () => {
        store.commit("parceiroModule/deleteParceiro", id);

        if (abrangenciaSelecionada.value) {
          const index = parceirosPorAbrangencia.value.indexOf(
            parceirosPorAbrangencia.value.filter(
              (parceiro) => parceiro.id == id
            )[0]
          );

          if (index !== -1) {
            parceirosPorAbrangencia.value.splice(index, 1);
            store.commit(
              "parceiroModule/setFiltrarPorAbrangencia",
              parceirosPorAbrangencia.value
            );
          }
        }
        store.commit("parceiroModule/resetParceirosPaginados", []);
        store.commit("parceiroModule/setPaginaAtual", 1);
        await this.getParceirosPaginados(store.getters["parceiroModule/getPaginaAtual"]);

        store.commit("setErros", "");
        store.commit("parceiroModule/setLoading", false);
      })
      .catch((error) => {
        throw error.response.data.errors[0];
    });
  },
};

export default ParceiroAPI;
