export default {
    namespaced: true,
    state: {
        abrangencias: [],
        abrangenciaSelecionada: ""
    },
    getters: {
        getData(state) {
            return state.abrangencias;
        },
        getAbrangenciaSelecionada(state){
            return state.abrangenciaSelecionada;
        }
    },
    mutations: {
        setData(state, payload) {
            state.abrangencias = payload;
        },
        editAbrangencia(state, payload) {
            state.abrangencias[payload.index] = payload.data;
        },
        setAbrangenciaSelecionada(state, payload){
            state.abrangenciaSelecionada = payload;
        },
        deleteAbrangencia(state, id) {
            state.abrangencias = state.abrangencias.filter((item) => item.id != id);
        }
    }
}