import store from "@/store";
import http from "./config";
import { computed } from "vue";

const abrangencias = computed(() => store.getters['abrangenciaModule/getData']);

const AbrangenciaAPI = {
    async getAbrangencias() {
      return http
        .get("/abrangencia")
        .then((response) => {
          store.commit("abrangenciaModule/setData", response.data);
          store.commit("setErros", "");
        })
        .catch((error) => store.commit("setErros", error.response.data));
    },
    async postAbrangencia(abrangencia) {
      return http
        .post("/abrangencia", {
          "local": abrangencia.local
        })
        .then((response) => {
          store.commit("setErros", "");
          return response;
        })
        .catch((error) => store.commit("setErros", error.response.data));
    },
    async editAbrangencia(dados) {
      return http
        .put(`/abrangencia/${dados.id}`, {
          "local": dados.local
        })
        .then((response) => {
          const index = abrangencias.value.indexOf(abrangencias.value.filter((abrangencia) => abrangencia.id == dados.id)[0]);
          store.commit("abrangenciaModule/editAbrangencia", {index, data:response.data});
          store.commit("setErros", "");
        })
        .catch((error) => store.commit("setErros", error.response.data));
    },
    async deleteAbrangencia(id) {
      return http
        .delete(`/abrangencia/${id}`)
        .then(() => {
          store.commit("abrangenciaModule/deleteAbrangencia", id);
          store.commit('setErros', '');
        })
        .catch((error) => store.commit('setErros', error.response.data));
    }
  };
  
export default AbrangenciaAPI;