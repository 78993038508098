<script setup>
import { defineProps, ref, computed, watch, defineEmits } from "vue";
import store from "@/store";

const emits = defineEmits(['busca-mudou', 'search']);

const props = defineProps({ data: Array });
const busca = ref("");

const disableInput = computed(() => store.getters["parceiroModule/isInputDisabled"]);

const categoriaSelecionada = computed(
  () => store.getters["categoriaModule/getCategoriaSelecionada"]
);

const abrangenciaSelecionada = computed(
  () => store.getters["abrangenciaModule/getAbrangenciaSelecionada"]
);

const isAdmin = computed(() => store.getters["authModule/getIsAdmin"]);

const filtrar = () => {
  let arrayParceiros = props.data;

  arrayParceiros = arrayParceiros.filter((item) => {
    return (!abrangenciaSelecionada.value || item.beneficio.abrangencia.some((a) => {
      return a.local == abrangenciaSelecionada.value || a.local == "TODOS OS COLABORADORES";
    })) &&
    (!categoriaSelecionada.value || item.categoria.nome === categoriaSelecionada.value);
  });

  return arrayParceiros.filter((item) =>
    Object.values(item)
      .toString()
      .toLocaleUpperCase()
      .match(busca.value.toLocaleUpperCase())
  );
};

function limparInputBusca() {
  busca.value = "";
}

watch([categoriaSelecionada, abrangenciaSelecionada, isAdmin], () => {
  limparInputBusca();
  emits.call(true, 'busca-mudou', true);
});

</script>

<template>
  <div class="search-box">
    <p class="control has-icons-left has-icons-right">
      <input
        class="input"
        type="text"
        placeholder="Buscar por nome"
        @input="$emit('search', filtrar())"
        v-model="busca"
        :disabled="disableInput"
      />
      <span class="icon is-small is-left">
        <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
      </span>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.input {
  background-color: var(--cor-background);
  border: none;
  border-radius: 5px;
  color: var(--cor-texto);
  height: 40px;
  font-size: 0.8rem;

  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 600px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 476px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 434px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 418px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 402px) {
    font-size: 0.65rem;
  }

  @media screen and (max-width: 318px) {
    font-size: 0.6rem;
  }

  @media screen and (max-width: 280px) {
    font-size: 0.4rem;
  }
}

.control.has-icons-left {
  @media screen and (max-width: 476px) {
    height: 3rem;
    font-size: 15px;
  }

  @media screen and (max-width: 402px) {
    height: 3rem;
    font-size: 14px;
  }

  @media screen and (max-width: 318px) {
    font-size: 13px;
  }
}

.search-box {
  box-shadow: 0;
  padding: 1rem 1rem 1rem 1rem;

  @media screen and (max-width: 476px) {
    padding:  0rem 1rem 0rem 1rem;
  }

  :hover,
  :focus {
    border: none;
    box-shadow: none;
  }
}
</style>
