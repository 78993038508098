<script setup>
import { defineProps, ref, watchEffect, onMounted } from 'vue';

const props = defineProps({
  label: String,
  placeholder: String,
  modelValue: String,
  value: String,
  type: String,
  required: Boolean,
  error: Boolean,
  helperText: String,
  readonly: Boolean,
  secondaryLabel: String,
  disabled: Boolean
})

const helperText = ref('');
const error = ref(false);

function validar() {
  if (props.required && !props.modelValue && !props.value) {
    helperText.value = "Este campo é obrigatório!";
    error.value = true;
  }
}

onMounted(() => {
  const botaoCancelar = document.querySelector('#botao-cancelar')
  const botaoFechar = document.querySelector('#delete')

  if (botaoCancelar) {
    botaoCancelar.addEventListener('click', () => {
      error.value = false
    })
  }
  if (botaoFechar) {
    botaoFechar.addEventListener('click', () => {
      error.value = false
    })
  }
})

watchEffect(() => {
  if (props.modelValue) {
    error.value = false
  }
})
</script>

<template>
  <div class="field">
    <span class="composite-label">
      <label class="label">{{ props.label }}
        <font-awesome-icon v-if="required" icon="fa-solid fa-star-of-life" class="icon" />
      </label>
      <p>{{ props.secondaryLabel }}</p>
    </span>
    <input 
      class="input" 
      :class="{ 'input': !error, 'input is-danger': error }" 
      :type="props.type" 
      :placeholder="props.placeholder"
      :value="props.modelValue ? props.modelValue : props.value"
      @input="(e) => $emit('update:modelValue', e.target.value)" 
      @blur="validar" 
      :readonly="props.readonly"
      :disabled="props.disabled">
    <p class="help is-danger" v-if="error">{{ helperText }}</p>
  </div>
</template>