<script setup>
import ModalConfirmar from "@/components/ModalConfirmar.vue";
import ModalImagem from "@/components/ModalImagem.vue";
import ObserverVue from "./ObserverVue.vue";
import ParceiroAPI from "@/services/ParceiroAPI";
import LoadingComponent from "@/components/LoadingComponent.vue";
import store from "@/store";
import { abrirModalEditar } from "@/utils/modalParceiroConfig";
import { transformaData } from "@/utils/utils";
import { computed, defineProps, onMounted, ref } from "vue";

const props = defineProps({ data: Array, textoBusca: Boolean });
const modal = ref("");
const modalConfirm = ref("");
const modalImagem = ref("");
const idParceiro = computed(
  () => store.getters["parceiroModule/getIdParceiro"]
);

function commitarParceiroParaExcluir(id) {
  store.commit("parceiroModule/setIdParceiro", id);
  modalConfirm.value = document
    .querySelector("#modal-confirm")
    .classList.add("is-active");
}

onMounted(() => {
  modal.value = document.querySelector("#modal-gerenciarEstabelecimento");
  modalImagem.value = document.querySelector("#modal-imagem");
  modalConfirm.value = document.querySelector("#modal-confirm");

});

let totalPaginas = computed(() => (store.getters["parceiroModule/getTotalPaginas"]));

const loadingMore = computed(() => store.getters["parceiroModule/getLoadingMore"]);

let page;

const loadMore = async () => {
  page = computed(() => (store.getters["parceiroModule/getPaginaAtual"]));

  if (page.value < totalPaginas.value && props.textoBusca == false) {
    store.commit("parceiroModule/setLoadingMore", true);
    store.commit("parceiroModule/setPaginaAtual", page.value = page.value + 1);

    await ParceiroAPI.getParceirosPaginados(store.getters["parceiroModule/getPaginaAtual"]);

    store.commit("parceiroModule/setLoadingMore", false);
  }
}
</script>

<template>
  <div class="table-box">
    <table class="table is-hoverable is-fullwidth">
      <thead class="table-header">
        <tr>
          <th class="text-head">Estabelecimento</th>
          <th class="text-head">Categoria</th>
          <th class="text-head">Início da Validade</th>
          <th class="text-head">Fim da Validade</th>
          <th class="text-head">Status</th>
          <th class="text-head">Editar</th>
          <th class="text-head">Editar Imagem</th>
          <th class="text-head">Excluir</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in props.data" :key="index">
          <td class="text-column">{{ item.nome }}</td>
          <td class="text-column">{{ item.categoria }}</td>
          <td class="text-column">{{ transformaData(item.inicioContrato) }}</td>
          <td class="text-column">{{ item.fimContrato ? transformaData(item.fimContrato) : "Sem prazo de validade"}}</td>
          <td class="text-column">{{ item.status }}</td>
          <td class="icone-column">
            <a @click="abrirModalEditar(modal, item)">
              <font-awesome-icon class="icon" icon="fa-solid fa-pen-to-square" />
            </a>
          </td>
          <td>
            <span class="container-imagem">
              <img class="imagem image is-64x64" :src="'data:image/png;base64,' + item.imagem" />
              <a @click="abrirModalEditar(modalImagem, item)">
                <font-awesome-icon class="icon-edit-image" icon="fa-solid fa-pen-to-square" />
              </a>
            </span>
          </td>
          <td class="icone-column">
            <a @click="commitarParceiroParaExcluir(item.id)">
              <font-awesome-icon class="icon trash" icon="fa-solid fa-trash" />
            </a>
          </td>
        </tr>
        <ObserverVue @intersect="loadMore"/>
      </tbody>
    </table>
    <div class="loadingMore" v-if="loadingMore">
      <LoadingComponent />
    </div>
  </div>
  <ModalConfirmar :id="idParceiro" />
  <ModalImagem />
</template>

<style lang="scss" scoped>
.container-imagem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.imagem {
  border-radius: 50%;
  margin-right: 10px;
}

.table-box {
  overflow-x: auto;

  table {
    background-color: transparent;

    th {
      color: var(--cor-principal) !important;
      font-size: 1.2rem;
      padding: 12px 15px;
    }

    td {
      color: var(--cor-texto);
      padding: 12px 15px;
    }

    .text-head {
      text-align: center;
    }

    .text-column {
      text-align: center;
      padding-top: 2.1rem;
    }

    .texto{
      padding-top: 2.1rem;
    }

    .icone-column {
      cursor: pointer;
      text-align: center;
    }
  }

  .icon {
    color: var(--cor-principal);
    text-decoration: none;
    transition: transform 0.1s ease-in-out;
    margin-top: 1.2rem;
  }
  .icon-edit-image {
    color: var(--cor-principal);
    text-decoration: none;
    transition: transform 0.1s ease-in-out;
    font-size: 1.5rem;
  }
  .icon:hover {
    transform: scale(1.09);
  }
  .icon-edit-image:hover {
    transform: scale(1.09);
  }

  .trash {
    color: #d50000;
  }

  .loadingMore {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
</style>
