import store from "@/store";
import { separarAbrangencias } from "./utils";

export const abrirModalCadastrar = (modal) => {
    store.commit('setAcaoModal', "Cadastrar")
    store.commit('parceiroModule/setParceiroSelecionadoEdit', {
        nome: "",
        imagem: "",
        categoria: "",
        contato: {
          telefone: "",
          whatsapp: "",
          email: "",
          rede: "",
        },
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          uf: "",
          complemento: "",
        },
        beneficio: {
          anuncio: "",
          abrangencia: [],
          comprovacao: "",
          observacao: "",
          inicioContrato: "",
          fimContrato: "",
          status: null,
        },
      });
      
    modal.classList.add('is-active');
}

export const abrirModalEditar = (modal, item) => {
    store.commit('setAcaoModal', "Editar")
    store.commit('parceiroModule/setParceiroSelecionadoEdit', {
        id: String(item.id),
        nome: String(item.nome),
        imagem: String(item.imagem),
        categoria: String(item.categoria),
        contato: {
            telefone: String(item.telefone),
            whatsapp: String(item.whatsapp),
            email: String(item.email),
            rede: String(item.rede),
        },
        endereco: {
            cep: String(item.cep),
            logradouro: String(item.logradouro),
            numero: String(item.numero),
            bairro: String(item.bairro),
            cidade: String(item.cidade),
            uf: String(item.uf),
            complemento: String(item.complemento),
        },
        beneficio: {
            comprovacao: String(item.comprovacao),
            inicioContrato: (item.inicioContrato),
            fimContrato: (item.fimContrato),
            status: Boolean(item.status),
            observacao: String(item.observacao),
            anuncio: String(item.anuncio),
            abrangencia: separarAbrangencias(Array(item.abrangencia))
        }
      });
      
    modal.classList.add('is-active');
}