<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import store from "@/store";
import ModalCategoria from "./ModalCategoria.vue";
import { abrirModal } from "@/utils/modalConfig";

const isAdmin = computed(()=> store.getters['authModule/getIsAdmin']);
const modal = ref("");
const init = ref(0);
const final = ref(5);
const arrayCopia = ref([]);

const categorias = computed(() =>
  store.getters["categoriaModule/getData"].sort((a, b) =>
    a.nome.localeCompare(b.nome)
  )
);

const categoriaSelecionada = computed(
  () => store.getters["categoriaModule/getCategoriaSelecionada"]
);

function alterarIconesEsquerda() {
  if (init.value > 0) {
    init.value--;
    final.value--;
    newArray();
  }
}

function alterarIconesDireita() {
  if (final.value <= categorias.value.length - 2) {
    init.value++;
    final.value++;
    newArray();
  }
}

function newArray() {
  arrayCopia.value = [];
  categorias.value.map((c, index) => {
    if (index >= init.value && index <= final.value) {
      arrayCopia.value.push(c);
    }
  });
}

function setFiltroCategoria(nome) {
  
  if (nome != "Gerenciar Categorias") {
    if (nome == categoriaSelecionada.value) {
      store.commit("categoriaModule/setCategoriaSelecionada", null);
    } else {
      store.commit("categoriaModule/setCategoriaSelecionada", nome);
    }
  }
}

watchEffect(() => {
  newArray()
})

onMounted(() => {
  modal.value = document.querySelector("#modal-categoria");
  newArray();
});
</script>

<template>
  <div :class="isAdmin ? 'container-admin' : 'container-usuario'">
    <font-awesome-icon
      @click="alterarIconesEsquerda()"
      :class="{'seta-esquerda': true, 'seta-esquerda-cinza': init === 0}"
      icon="fa-solid fa-arrow-left"
    />
    <span
      v-for="categoria in arrayCopia"
      :key="categoria.nome"
      :class="categoriaSelecionada == categoria.nome ? 'ativo' : 'inativo'"
      data-tooltip
      :title="categoriaSelecionada == categoria.nome ? `Clique para remover este filtro` : 'Clique para aplicar este filtro'"
    >
      <p @click="setFiltroCategoria(categoria.nome)" class="categoria">
        {{ categoria.nome.toUpperCase() }}
      </p>
    </span>
    <font-awesome-icon
      @click="alterarIconesDireita()"
      :class="{'seta-direita': true, 'seta-direita-cinza': final === categorias.length - 1 || final > categorias.length - 1}"
      icon="fa-solid fa-arrow-right"
    />
    <div
      v-if="isAdmin"
      :class="categoriaSelecionada == 'Gerenciar Categorias' ? 'ativo' : 'inativo'"
      class="gerenciar"
    >
      <span
        id="abrir-modal-categoria"
        @click="abrirModal(modal)"
      >
        <p class="categoria">GERENCIAR CATEGORIAS</p>
      </span>
    </div>
    <ModalCategoria />
  </div>
</template>

<style lang="scss" scoped>
.seta-esquerda {
  cursor: pointer;
  margin-left: 3.5rem;
  color: var(--cor-principal);
  font-size: 1.5rem;
}
.seta-direita {
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 2.5rem;
  color: var(--cor-principal);
  font-size: 1.5rem;
}
.seta-esquerda-cinza {
  pointer-events: none;
  margin-left: 3.5rem;
  margin-right: 2.5rem;
  color: #c5c1c1;
  font-size: 1.5rem;
}
.seta-direita-cinza {
  pointer-events: none;
  margin-left: 1rem;
  margin-right: 2.5rem;
  color: #c5c1c1;
  font-size: 1.5rem;
}

.gerenciar {
  width: 30%!important;
}

.container-usuario {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 3.5rem;

  @media screen and (max-width: 1408px) {
    gap: 2.7rem;
  }

  @media screen and (max-width: 1216px) {
    gap: 2.4rem;
  }

  @media screen and (max-width: 888px) {
    gap: 2.2rem;
  }

  @media screen and (max-width: 865px) {
    gap: 2rem;
  }

  @media screen and (max-width: 838px) {
    gap: 1.7rem;
  }

  @media screen and (max-width: 820px) {
    gap: 1.5rem;
  }

  @media screen and (max-width: 796px) {
    gap: 1.2rem;
  }

  .inativo {
    width: 100%;



    .categoria {
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transition: transform 0.1s ease-in-out;
      transform: scale(0.9);
      padding: 0 0.3rem;
      font-weight: var(--font-bold);
      font-size: 0.9rem;
      text-align: center;
      color: var(--cor-contraste);

      @media screen and (max-width: 1259px) {
        font-size: 0.85rem;
      }

      @media screen and (max-width: 1107px) {
        font-size: 0.8rem;
      }

      @media screen and (max-width: 606px) {
        font-size: 0.75rem;
      }

      @media screen and (max-width: 504px) {
        font-size: 0.7rem;
      }

      @media screen and (max-width: 447px) {
        font-size: 0.65rem;
      }
    }

    .categoria:hover {
      transform: scale(1.09);
    }
  }

  .ativo {
    width: 100%;

    .categoria {
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transition: transform 0.1s ease-in-out;
      transform: scale(1.09);
      padding: 0 0.3rem;
      font-weight: var(--font-bold);
      font-size: 0.9rem;
      text-align: center;
      color: var(--cor-principal);

      @media screen and (max-width: 1259px) {
        font-size: 0.75rem;
      }

      @media screen and (max-width: 1107px) {
        font-size: 0.65rem;
      }

      @media screen and (max-width: 606px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 504px) {
        font-size: 0.5rem;
      }

      @media screen and (max-width: 447px) {
        font-size: 0.45rem;
      }
    }

    .categoria:hover {
      transform: scale(1.09);
    }
  }
}

.container-admin {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 3.5rem;

  @media screen and (max-width: 1408px) {
    gap: 2.2rem;
  }

  @media screen and (max-width: 1216px) {
    gap: 1.9rem;
  }

  @media screen and (max-width: 930px) {
    gap: 1.7rem;
  }

  @media screen and (max-width: 906px) {
    gap: 1.3rem;
  }

  @media screen and (max-width: 865px) {
    gap: 1.05rem;
  }

  @media screen and (max-width: 820px) {
    gap: 1rem;
  }

  @media screen and (max-width: 796px) {
    gap: 0.8rem;
  }

  .inativo {
    width: 100%;

    .categoria {
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transition: transform 0.1s ease-in-out;
      transform: scale(0.9);
      padding: 0 0.3rem;
      font-weight: var(--font-bold);
      font-size: 0.9rem;
      text-align: center;
      color: var(--cor-contraste);

      @media screen and (max-width: 1259px) {
        font-size: 0.75rem;
      }

      @media screen and (max-width: 1107px) {
        font-size: 0.65rem;
      }

      @media screen and (max-width: 606px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 504px) {
        font-size: 0.5rem;
      }

      @media screen and (max-width: 447px) {
        font-size: 0.45rem;
      }
    }

    .categoria:hover {
      transform: scale(1.09);
    }
  }

  .ativo {
    width: 100%;

    .icon-box {
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transition: transform 0.1s ease-in-out;
      transform: scale(1.09);

      .icon {
        color: var(--cor-principal);
        width: 100%;
      }
    }
    .categoria {
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transition: transform 0.1s ease-in-out;
      transform: scale(1.09);
      padding: 0 0.3rem;
      font-weight: var(--font-bold);
      font-size: 0.9rem;
      text-align: center;
      color: var(--cor-principal);

      @media screen and (max-width: 1259px) {
        font-size: 0.75rem;
      }

      @media screen and (max-width: 1107px) {
        font-size: 0.65rem;
      }

      @media screen and (max-width: 606px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 504px) {
        font-size: 0.5rem;
      }

      @media screen and (max-width: 447px) {
        font-size: 0.45rem;
      }
    }
  }

  .categoria:hover {
      transform: scale(1.09);
    }
}
</style>
