import { createStore } from 'vuex'
import categoriaModule from './modules/categoriaModule'
import abrangenciaModule from './modules/abrangenciaModule'
import beneficioModule from './modules/beneficioModule';
import parceiroModule from './modules/parceiroModule';
import authModule from './modules/authModule';

const store = createStore({
  state: {
    acaoModal: "",
    erros: "",
  },
  getters: {
  },
  mutations: {
    setErros(state, payload){
      state.erros = payload;
    },
    setAcaoModal(state, payload) {
      state.acaoModal = payload;
    },
  },
  actions: {
  },
  modules: {
    categoriaModule,
    abrangenciaModule,
    beneficioModule,
    parceiroModule,
    authModule,
  }
})

export default store;