<script setup>
import { defineProps } from "vue";

const props = defineProps({
    textoBotao: String
    })
</script>

<template>
    <button class="button main-button">
            <span class="text">
            {{ props.textoBotao }}
            </span>
    </button>
</template>