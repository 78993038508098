<script setup>
import { computed, defineProps } from "vue";

import { transformaData } from "@/utils/utils";



const props = defineProps({
  imagem: String,
  estabelecimento: String,
  categoria: String,
  telefone: String,
  whatsapp: String,
  email: String,
  rede: String,
  cep: String,
  logradouro: String,
  numero: String,
  bairro: String,
  cidade: String,
  uf: String,
  complemento: String,
  comprovacao: String,
  fimContrato: String,
  observacao: String,
  anuncio: String,
  abrangencia: String,
});


const imagem = computed(()=> 'data:image/png;base64,' + props.imagem);

function reiniciarScrollBar(){
  var bodyDoModal = document.getElementById('modal-body');
  bodyDoModal.scrollTop = 0;
}

function fecharModal($el) {
  reiniciarScrollBar()
  $el.classList.remove("is-active");
}
</script>

<template>
  <div class="modal" id="modal-infoEstabelecimento">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="card-image">
          <figure class="image is-rounded">
            <img
              class="is-rounded has-background-white"
              :src="imagem"
              alt="logo"
            />
          </figure>
        </div>
        <button
          @click="fecharModal($el)"
          class="delete is-large"
          aria-label="close"
        ></button>
      </header>
      <section class="modal-card-body" id="modal-body">
        <p class="texto-estabelecimento">
          {{ props.estabelecimento }}
        </p>
        <span class="texto-icone">
          <font-awesome-icon
            class="small-icon"
            icon="fa-solid fa-graduation-cap"
          />
          <p class="texto">
            {{ props.categoria }}
          </p>
        </span>
        <span class="texto-icone">
          <font-awesome-icon class="small-icon" icon="fa-solid fa-gift" />
          <p class="texto anuncio">
            {{ props.anuncio }}
          </p>
        </span>
        <span class="texto-icone" v-if="props.email">
          <font-awesome-icon
            class="small-icon"
            icon="fa-solid fa-envelope"
          />
          <p class="texto">
            {{ props.email }}
          </p>
        </span>
        <span class="texto-icone" v-if="props.rede">
          <font-awesome-icon
            class="small-icon"
            icon="fa-solid fa-at"
          />
          <div class="texto">
            <div v-if="props.rede.startsWith('http')">
              <a :href="props.rede" target="_blank" >{{props.rede}}</a>
            </div>
            <p v-else> {{ props.rede }}</p>
          </div>
        </span>
        <span class="texto-icone" v-if="props.whatsapp || props.telefone">
          <font-awesome-icon
            class="whatsapp-icon"
            icon="fa-brands fa-whatsapp"
            v-if="props.whatsapp"
          />
          <p class="texto" v-if="props.whatsapp">
            {{ props.whatsapp }}
          </p>

          <font-awesome-icon
            class="small-icon"
            icon="fa-solid fa-address-book"
            v-if="props.telefone"
          />
          <p class="texto" v-if="props.telefone">
            {{ props.telefone }}
          </p>
        </span>
        <span
          class="texto-icone"
          v-if="
            props.logradouro ||
            props.numero ||
            props.complemento ||
            props.bairro ||
            props.cidade ||
            props.uf ||
            props.cep
          "
        >
          <font-awesome-icon
            class="small-icon"
            icon="fa-solid fa-location-dot"
          />
            <p class="texto">
              {{ props.logradouro ? props.logradouro + "," : "" }}
              {{ props.numero ? props.numero + "," : "" }}
              {{ props.complemento ? props.complemento + "," : "" }}
              {{ props.bairro ? props.bairro + "," : "" }}
              {{ props.cidade ? props.cidade + "," : "" }}
              {{ props.uf ? props.uf + "," : "" }}
              {{ props.cep ? props.cep : "" }}
            </p>
        </span>
        <span class="texto-icone" v-if="props.comprovacao">
          <font-awesome-icon class="small-icon" icon="fa-solid fa-check" />
          <p class="texto">
            {{ props.comprovacao }}
          </p>
        </span>
        <span class="texto-icone" v-if="props.observacao">
          <font-awesome-icon
            class="small-icon"
            icon="fa-solid fa-circle-info"
          />
          <p class="texto">
            {{ props.observacao }}
          </p>
        </span>
        <div class="abrangencia">
          <p class="texto">
            {{ props.abrangencia }} <br />{{ props.fimContrato ? transformaData(props.fimContrato) : "Sem prazo de validade"}}
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-card {
  border-radius: 12px;
  border: none !important;
}

.modal-card-head {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    margin-left: 55px;
    margin-right: 55px;
  }

  .card-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin-top: 2rem;

    img {
      height: 200px;
      width: 200px;
      object-fit: cover;
      border: 4px solid var(--cor-contraste);
      border-radius: 50%;

      @media screen and (max-width: 768px) {
        height: 150px;
        width: 150px;
      }

      @media screen and (max-width: 600px) {
        height: 130px;
        width: 130px;
      }

      @media screen and (max-width: 476px) {
        height: 100px;
        width: 100px;
      }

      @media screen and (max-width: 434px) {
        height: 80px;
        width: 80px;
      }

      @media screen and (max-width: 418px) {
        height: 75px;
        width: 75px;
      }

      @media screen and (max-width: 402px) {
        height: 70px;
        width: 70px;
      }

      @media screen and (max-width: 318px) {
        height: 65px;
        width: 65px;
      }
    }
  }

  .delete {
    position: absolute;
    top: 100%;
    left: 92%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin-top: 2rem;

    @media screen and (max-width: 476px) {
      left: 89%;
    }

    @media screen and (max-width: 434px) {
      left: 86%;
    }
  }
}

.is-large.delete {
  @media screen and (max-width: 476px) {
    height: 18px;
    max-height: 18px;
    max-width: 18px;
    min-height: 18px;
    min-width: 18px;
    width: 18px;
  }

  @media screen and (max-width: 434px) {
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px;
  }
}

.modal-card-body {
  height: auto;
  border-radius: 12px;
  background-color: var(--cor-secundaria);
  position: relative;
  padding: 5rem 1.5rem 1.5rem 1.5rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 1rem 1.5rem 1rem;
    margin-left: 55px;
    margin-right: 55px;
  }

  @media screen and (max-width: 476px) {
    padding: 1rem 1rem 1.5rem 1rem;
  }
}

.texto-estabelecimento {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-bold);
  color: var(--cor-texto);
  font-size: 1.5rem;
  margin-top: 45px;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    margin-top: 76px;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
    margin-top: 70px;
  }

  @media screen and (max-width: 476px) {
    font-size: 1rem;
    margin-top: 57px;
  }

  @media screen and (max-width: 434px) {
    font-size: 0.8rem;
    margin-top: 45px;
  }

  @media screen and (max-width: 418px) {
    font-size: 0.78rem;
    margin-top: 41px;
  }

  @media screen and (max-width: 402px) {
    font-size: 0.75rem;
    margin-top: 35px;
  }

  @media screen and (max-width: 318px) {
    font-size: 0.7rem;
    margin-top: 33px;
  }
}

.texto-icone {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
  word-break: break-word;

  .texto {
    color: var(--cor-texto);
    font-weight: var(--font-medium);
    font-size: 1rem;
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-line;
    word-break: break-word;
    text-align: left;

    @media screen and (max-width: 476px) {
      font-size: 0.75rem;
    }

    @media screen and (max-width: 434px) {
      font-size: 0.65rem;
    }

    @media screen and (max-width: 418px) {
      font-size: 0.6rem;
    }

    @media screen and (max-width: 402px) {
      font-size: 0.65rem;
    }

    @media screen and (max-width: 318px) {
      font-size: 0.5rem;
    }
  }

  .anuncio {
    color: #0eab31;
  }
  .small-icon {
    font-size: 1.2rem;
    color: var(--cor-principal);

     @media screen and (max-width: 600px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 476px) {
        font-size: 0.8rem;
      }
      @media screen and (max-width: 434px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 418px) {
        font-size: 0.55rem;
      }

      @media screen and (max-width: 402px) {
        font-size: 0.6rem;
      }
  }

  .texto-endereco {
    color: var(--cor-texto);
    font-weight: var(--font-medium);
    font-size: 1rem;
    margin-left: 10px;
    margin-right: 5px;
    text-align: justify;

    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.65rem;
    }
  }

  .whatsapp-icon {
    font-size: 1.5rem;
    color: green;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.8rem;
    }
  }
  .extra-small-icon {
    font-size: 0.3rem;
    color: var(--cor-principal);
    @media screen and (max-width: 768px) {
      font-size: 0.25rem;
    }

    @media screen and (max-width: 476px) {
      font-size: 0.2rem;
    }
  }
}
.abrangencia {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1rem 1rem 1rem 1rem;
  font-size: 1rem;
  color: var(--cor-texto);

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 476px) {
    font-size: 0.6rem;
  }
}
</style>
