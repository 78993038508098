<script setup>
import ParceiroAPI from "@/services/ParceiroAPI";
import { onMounted, defineProps, ref } from "vue";
import { fecharModal } from "@/utils/modalConfig";
import toast from "@/utils/toastConfig";
import BotaoComponent from "./BotaoComponent.vue";
import store from "@/store";

const props = defineProps({ id: String });

const modal = ref("");
const modalConfirm = ref("");

const loading = ref(false);

function deletarParceiro(id) {

  loading.value = true;

  ParceiroAPI.deleteParceiro(id).then( async () => {
    loading.value = false;
    toast.abrirToast("success", "Estabelecimento excluído com sucesso!");
    fecharModal(modalConfirm.value);
    store.commit("parceiroModule/setInputDisabled", true);
    await ParceiroAPI.getTodosParceiros();
    store.commit("parceiroModule/setInputDisabled", false);
  }).catch((error) => {
    toast.abrirToast("error", error);
  });
}

onMounted(() => {
  modalConfirm.value = document.querySelector("#modal-confirm");
  modal.value = document.querySelector("#modal-gerenciarEstabelecimento");
});
</script>

<template>
  <div class="modal" id="modal-confirm">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <p>Deseja realmente excluir este estabelecimento?</p>
      </section>
      <footer class="modal-card-foot">
        <button v-if="!loading" class="button delete-button" @click="deletarParceiro(props.id)">
          EXCLUIR PERMANENTEMENTE
        </button>
        <button class="button is-danger is-loading" v-if="loading"></button>
        <BotaoComponent
          class="botao-cancelar"
          @click="fecharModal(modalConfirm)"
          textoBotao="CANCELAR"
          :disabled="loading"
        />
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-card {
  background-color: var(--cor-secundaria);
  border-radius: 12px;
  border: none;
  width: 40%;
}

.modal-card-body p {
    font-size: 1.2rem;
}

.modal-card-foot {
  justify-content: flex-end;

  .delete-button {
    width: 14rem;
    font-size: 14px;
    font-weight: var(--font-medium);
    border: none;
    padding: 1.2rem;
    background-color: #D50000;
    color: var(--cor-secundaria);
  }

  .delete-button:hover {
    transform: translateY(-1px) scale(1.005) translateZ(0);
    box-shadow: 0 5px 10px var(--cor-contraste);
  }
}

.button {
  width: 8rem;
}
</style>