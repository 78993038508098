<script setup>
import { onMounted, ref } from "vue";
import BotaoCadastrar from "@/components/BotaoCadastrar.vue";
import { abrirModalCadastrar } from "@/utils/modalParceiroConfig";
import GerenciarEstabelecimentoModal from "./ModalGerenciarEstabelecimento.vue";
import store from "@/store"

const modal = ref("");

onMounted(() => {
  modal.value = document.querySelector('#modal-gerenciarEstabelecimento');
})

function cadastrar(){
  store.commit("categoriaModule/setCategoriaSelecionada", null);
  abrirModalCadastrar(modal.value);
}
</script>

<template>
    <div class="botao">
      <BotaoCadastrar @click="cadastrar" texto-botao="CADASTRAR ESTABELECIMENTO" />
      <GerenciarEstabelecimentoModal />
    </div>
</template>

<style lang="scss" scoped>
.botao {
  padding: 1rem;
}
</style>