<script setup>
import { onMounted, ref, computed } from "vue";
import store from "@/store";
import { fecharModal } from "@/utils/modalConfig";
import ParceiroAPI from "@/services/ParceiroAPI";
import toast from "@/utils/toastConfig";
import BotaoComponent from "@/components/BotaoComponent.vue";

const imagemInserida = ref({});
const uploadImage = ref(null);
const nomeDaImagemInserida = ref("");
const modal = ref("");

const parceiro = computed(
  () => store.getters["parceiroModule/getParceiroSelecionadoEdit"]
);

const loading = ref(false);

onMounted(() => {
  modal.value = document.querySelector("#modal-imagem");
});

function cancelar() {
  fecharModal(modal.value);
  limparInputDeImagem();
}

function inserirImagem() {
  imagemInserida.value = uploadImage.value.files[0];
  nomeDaImagemInserida.value = imagemInserida.value.name;

  const limiteDeCaracteres = 20;

  if (nomeDaImagemInserida.value.length > limiteDeCaracteres) {
    nomeDaImagemInserida.value =
      nomeDaImagemInserida.value.slice(0, limiteDeCaracteres) + "...";
  }
}

function limparInputDeImagem() {
  uploadImage.value.value = "";
  nomeDaImagemInserida.value = "";
  imagemInserida.value = {};
}

function editarImagem() {
  if (!nomeDaImagemInserida.value) {
    return toast.abrirToast("error", "Selecione uma imagem!");
  }

  loading.value = true;

  ParceiroAPI.editImagem(parceiro.value, imagemInserida.value).then( async () => {
    toast.abrirToast("success", "Imagem editada com sucesso!");
    loading.value = false;
    fecharModal(modal.value);
    limparInputDeImagem();
    store.commit("parceiroModule/setInputDisabled", true);
    await ParceiroAPI.getTodosParceiros();
    store.commit("parceiroModule/setInputDisabled", false);
  }).catch((error) => {
    toast.abrirToast("error", error);
  });
}

function editarImagemCarregar() {
  editarImagem();
}
</script>

<template>
  <div class="modal" id="modal-imagem">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="container-titulo">
          <span class="texto-titulo"> Editar Imagem: </span>
          <p class="titulo-estabelecimento">{{ parceiro.nome }}</p>
        </div>
        <div class="botao-fechar">
          <button
            id="delete"
            class="delete is-large"
            :class="loading ? 'botao_desabilitado' : null"
            aria-label="close"
            @click="cancelar"
            :disabled="loading"
          ></button>
        </div>
      </header>
      <section class="modal-card-body">
        <span class="container-imagem">
          <div>
            <label class="label">Imagem Atual</label>
            <img
              class="imagem image is-128x128"
              :src="'data:image/png;base64,' + parceiro.imagem"
            />
          </div>
          <div class="content-image">
            <label class="label">Imagem </label>
            <label class="botao-upload-image" for="upload-image">
              <span v-if="!nomeDaImagemInserida">Selecione uma imagem</span>
              <span v-else class="nome-da-imagem-selecionada">
                {{ nomeDaImagemInserida }}</span
              >
              <font-awesome-icon class="small-icon" icon="fa-solid fa-upload" />
            </label>
            <input
              type="file"
              accept="image/*"
              class="upload-image"
              id="upload-image"
              ref="uploadImage"
              @change="inserirImagem"
              :disabled="loading"
            />
          </div>
        </span>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-info is-loading" v-if="loading"></button>
        <BotaoComponent @click="editarImagemCarregar" textoBotao="SALVAR" v-if="!loading" />
        <BotaoComponent
          class="botao-cancelar"
          @click="cancelar"
          textoBotao="CANCELAR"
          :disabled="loading"
        />
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container-imagem {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.content-image {
  width: 200px;
}

.texto-titulo {
  color: var(--cor-principal);
  font-size: 1.2rem;
  font-weight: var(--font-medium);
  text-align: center;
}

.titulo-estabelecimento {
  font-weight: var(--font-bold);
  color: var(--cor-texto);
  font-size: 1.2rem;
  text-align: center;
  margin-left: 10px;
}

.container-titulo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.imagem {
  border-radius: 50%;
  margin-right: 10px;
}

.modal-card {
  background-color: var(--cor-secundaria);
  border-radius: 12px;
  border: none;
  width: 40%;
}

.modal-card-head {
  border: none;
  background-color: var(--cor-background);
  justify-content: space-between;
  align-items: center;
  height: 4.5rem;
}

.modal-card-body p {
  font-size: 1.2rem;
}

.modal-card-foot {
  justify-content: flex-end;
}

.small-icon {
  font-size: 1.1rem;
  color: var(--cor-principal);
}

button {
  width: 7rem;
}

.botao_desabilitado {
  opacity: 70%;
  cursor: not-allowed;
}
</style>