import store from "@/store";
import http from "./config";
import { computed } from "vue";

const categorias = computed(() => store.getters['categoriaModule/getData']);

const CategoriaAPI = {
  async getCategorias() {
    return http
      .get("/categoria")
      .then((response) => {
        store.commit("categoriaModule/setData", response.data);
        store.commit("setErros", "");
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },
  async postCategoria(categoria) {
    return http
      .post("/categoria", {
        "nome": categoria
      })
      .then((response) => {
        store.commit("categoriaModule/postCategoria", response.data);
        store.commit("setErros", "");
        return response;
      })
      .catch((error) => {
        throw error.response.data.errors[0];
      });
  },
  async editCategoria(dados) {
    return http
      .put(`/categoria/${dados.id}`, {
        "nome": dados.nome
      })
      .then((response) => {
        const index = categorias.value.indexOf(categorias.value.filter((categoria) => categoria.id == dados.id)[0]);
        store.commit("categoriaModule/editCategoria", {index, data: response.data});
        store.commit("setErros", "");
      })
      .catch((error) => {
        throw error.response.data.errors[0];
      });
  },
  async deleteCategoria(id) {
    return http
      .delete(`/categoria/${id}`)
      .then(() => {
        store.commit("categoriaModule/deleteCategoria", id);
        store.commit('setErros', '');
      })
      .catch((error) => {
        throw error.response.data.errors[0];
      });
  }
};

export default CategoriaAPI;
