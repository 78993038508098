<script setup>
import { defineProps } from "vue";

const props = defineProps({
    textoBotao: String
})
</script>

<template>
    <button class="button main-button">
        <span class="text">
            <font-awesome-icon class="icone" icon="fa-solid fa-plus" />
            {{ props.textoBotao }}
        </span>
    </button>
</template>

<style lang="scss" scoped>
.icone {
    margin-right: 0.2rem;
}
</style>